.content-list {
	.news-list__item {
		a:hover {
			.news-list__item-title  {
				color: $accent-color;
			}
		}
	}

	.news-list__item-title {
		font-family: $font-family;
		font-weight: 700;
	}

	&__list {
		padding-left: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			margin-right: 0px;
			margin-left: 0px;
		}
	}
}

.content-item {
	list-style: none;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 50px;
	margin-bottom: 30px;
	padding-top: 15px;
	line-height: 19px;
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	transition: box-shadow 0.1s linear;
	@include media-breakpoint-down(xs) {
		text-align: center;
		margin-bottom: 10px;
	}

	&:hover {
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,23);
		-moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.23);
		box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.23);

		.content-item__title {
			text-decoration: underline;
			color: $accent-color;
		}
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}

	&__wrapper {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	&__img {
		display: block;
		align-self: center;
		width: 100%;
		min-height: 200px;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}

	&__title {
		font-family: $font-family-normal;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 5px;
		margin-top: 10px;
		font-style: normal;
		max-width: 300px;
		@include media-breakpoint-down(xs) {
			text-align: left;
		}
	}

	&__price {
		font-family: $font-family-normal;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 0px;
		@include media-breakpoint-down(xs) {
			text-align: left;
		}
	}

	&__offers {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 15px;
		font-size: 18px;
		font-family: $font-family-normal;
		height: 37px;
		background-color: #F8F8F8;
		border-left: 3px solid $accent-color;
		@include media-breakpoint-down(xs) {
			width: 100%;
			margin-left: 0;
			max-width: initial;
		}
	}

	.more-btn {
		position: absolute;
		bottom: 0px;
		max-width: 284px;
		@include media-breakpoint-down(lg) {
			max-width: 289px;
		}
		@include media-breakpoint-down(md) {
			max-width: 329px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 224px;
		}

		@include media-breakpoint-down(xs) {
			max-width: 280px;
		}
	}

	.news-list__item-title {
		@include media-breakpoint-down(md) {
			margin-top: 10px;
			padding-left: 15px;
			padding-right: 15px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 0px;
			padding-left: 0px;
			padding-right: 0px;
		}
		@include media-breakpoint-down(xs) {
			margin-top: 10px;
		}
	}

	.news-list__item-desc {
		@include media-breakpoint-down(md) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

.content-container {
	img {
		max-width: 100%;
	}
}