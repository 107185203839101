.car-in-stock-title {
	margin-bottom: 25px;

	&__order {
		font-family: $font-family-normal;
		font-size: 15px;
		font-weight: 700;
		margin-bottom: 0px;
		@include media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}

	&__specs {
		font-size: 15px;
		font-family: $font-family-normal;
		@include media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}
}
