.dbfail {
	text-align: center;
	height: 500px;
	.container {
		min-height: 100%;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 500px;
	}

	&__title {
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		font-family: $font-family-normal;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	&__text {
		font-size: 18px;
		line-height: 24px;
		font-family: $font-family-normal;
	}
}