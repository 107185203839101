.taho-container {
  .standard-gallery-tahoe-top {
    max-height: 500px;
    overflow: hidden;
    animation: appear 1s;
    
    @include media-breakpoint-down(lg) {
      .nav-list {
        margin-top: -58px;
      }
      .slick-slider {
        height: auto !important;
        margin-bottom: -47px;
        .slick-list {
          height: auto !important;
          .slick-slide {
            height: auto !important;
            .slider-item-standard {
              height: auto !important;
              .content-standard {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes appear  {
    0% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }
    50% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }

    99% {
      opacity: 0.7;
      height: 500px;
      overflow: hidden;
    }

    100% {
      opacity: 1;
      overflow: unset;
      height: unset;
    }
  }

  @include media-breakpoint-down(sm) {
    .more-btn {
      max-width: 100% !important;
    }
  }

  .big_features_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .features_1_3 {
     flex: 1;
    }
  }
}
