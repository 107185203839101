.call-back-container {
	padding: 0 !important;
	width: 840px !important;
	@include media-breakpoint-down(md) {
		width: 640px !important;
	}

	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.input-form-wrapper {
		width: 100% !important;
		max-width: 100% !important;
		flex: 100% !important;
		.item-1, .item-2, .item-3, .item-4, item-5 {
			padding-left: 0 !important;

		}
	}

	.form_inner_wrapper {
		width: 100% !important;
		max-width: 100% !important;
		flex: 100% !important;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		p {
			display: none;
		}
	}

	.field_wrapper {
		width: 47%;
		max-width: 47%;
		@include media-breakpoint-down(sm) {
			width: 100%;
			max-width: 100%;
		}
		.label_wrapper {
			margin-top: 0px;
			height: 20px;
			flex: 100%;
			max-width: 100%;
			margin-bottom: 7px;
		}

		&:nth-child(4) {
			width: 100%;
			max-width: 100%;
			margin-top: 10px;


			.error_wrapper {
				position: relative;
				top: -25px;
				@include media-breakpoint-down(md) {
					top: -10px;
				}
				@include media-breakpoint-down(xs) {
					top: -15px;
				}
			}
		}

		&:nth-child(7) {
			.label_wrapper {
				margin-top: 20px;
			}
		}
	}

	.ik_select_link_focus {
		box-shadow: none;
	}

	.ik_select {
		top: 0px !important;
	}

	.disclaimer, .ftype_checkbox {
		visibility: visible !important;
		display: inline-block;
	}

	.disclaimer--required {
		margin-top: 0 !important;
	}

	.ftype_checkbox {
		.form-control {
			top: -41px;
			@include media-breakpoint-down(md) {
				top: -45px;
			}
		}
	}

	.field_wrapper .label_wrapper {
		visibility: visible !important;
	}
	.ik_select_link_novalue {
		.ik_select_link_text {
			opacity: 0;
			visibility: hidden;
		}
	}

	.i_agree_personal_data-label {
		display: block;
		padding-left: 25px;
		@include media-breakpoint-down(xs) {
			font-size: 13px;
			padding-left: 15px !important;
		}
	}

	.disclaimer {
		font-size: 15px;
		margin-bottom: 0;
	}
}