.contacts {
	font-family: $font-family-normal;
	margin-top: 70px;
	background-color: #434242;
	padding-top: 20px;
	color: #fff;
	@include media-breakpoint-down(xs) {
		max-height: 73px;
	}
	&__wrapper {
		padding-left: 0px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		max-height: 73px;
		@include media-breakpoint-down(xs) {
			justify-content: center;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			padding-left: 0;
			flex-direction: column;
			max-height: unset;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 0px;
		}
	}
	
	&__item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		font-size: 14px;
		color: #fff;
		transition: color 0.1s linear;
		margin-bottom: 1rem;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		@include media-breakpoint-down(xs) {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		&:hover {
			color: $accent-color !important;
			text-decoration: none;
			.a {
				fill: $accent-color;
			}
			.p {
				color: $accent-color;
			}
		}
	}
	&__item:hover {
		.contacts__item-title {
			color: $accent-color;
		}
		p {
			color: $accent-color;
		}
	}

	&__item--address {
		@include media-breakpoint-down(sm) {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
		}
		@include media-breakpoint-down(md) {
			flex: 408;
		}
	}
	&__item--phone {
		@include media-breakpoint-up(md) {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			flex: 1;
		}
		@include media-breakpoint-down(md) {
			flex: 226;

		}
		@include media-breakpoint-down(xs) {
			flex: 1;
			.contacts__item-text-block {
				display: none;
			}
		}
		@include media-breakpoint-down(sm) {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
		}
	}

	&__item--time {
		@include media-breakpoint-up(md) {
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
		}
		@include media-breakpoint-down(md) {
			flex: 275;
		}
		@include media-breakpoint-down(sm) {
			min-width: auto;
		}
		@include media-breakpoint-down(sm) {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
		}
	}

	&__item--address, &__item--time {
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}

	&__item-icon {
		margin-right: 10px;
		padding-top: 4px;
		@include media-breakpoint-down(xs) {
			margin-right: 8px;
			margin-left: 8px;
		}
		.a {
			fill: #fff;
			transition: fill 0.1s linear;
		}
	}
	
	&__item-title {
		font-family: $font-family;
		font-style: italic;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 5px;
		text-align: left;
		color: #fff;
		transition: color 0.1s linear;
		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}
	}
	&__item-text-block {
		opacity: 1;
		transition: opacity 0.1s linear 0.25s;

	}

	&__item-text {
		font-size: 13px;
		margin-bottom: 0;
		color: #fff;
		transition: color 0.1s linear;
		@include media-breakpoint-down(lg) {
			font-size: 12px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 13px;
			text-align: left;
		}
		&--phone {
			display: block;
			color: inherit;
			&:hover {
				color: inherit;
				text-decoration: none;
			}
		}
	}
}