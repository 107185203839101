.standard-tabs {
	margin-top: 80px;
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
	.wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
	}
	.tab-item {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		font-family: $font-family-normal;
		margin-right: 40px;
		border-bottom: 2px solid $accent-color;
		font-weight: 400;
		font-size: 17px;
		padding: 10px 20px;
		text-align: center;
		margin-bottom: 40px;
		width: 145px;
		&:hover {
			text-shadow: 0 0 .5px #333, 0 0 .5px #333;
			background-color: #F8F8F8;
		}
		@include media-breakpoint-down(xs) {
			margin-right: 0;
		}
	}
}