.car-in-stock-specs-table {
	margin-top: 80px;
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
	.block-table {
		margin-top: 0;
		margin-bottom: 30px;
	}

	.table {
		display: table;
	}

	.table-cell  {
		border-left: none;
		border-right: none;
		font-size: 15px;
		&:last-child {
			text-align: right;
		}
	}

	.table-cell-head {
		font-size: 16px;
		border: none;
		padding-top: 20px;
		margin-bottom: 30px;
	}
}