.used-similar {

	&__wrapper {
		position: relative;
	}

	h2 {
		font-size: 28px;
		text-transform: uppercase;
		font-weight: 700;
		font-style: italic;
		text-transform: uppercase;
	}
}