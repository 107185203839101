.old-browser {
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 400px;
		margin-top: 200px;
		@include media-breakpoint-down(md) {
			padding-top: 60px;
		}
	}
	&__title {
		font-size: 40px;
		margin-bottom: 21px;
		font-family: $font-family-normal;
		font-style: normal;
		font-weight: 400;
		text-transform: none;
		text-align: center;
	}

	&__text {
		text-align: center;
		font-family: $font-family-normal;
		font-size: 16px;
		line-height: 21px;
		margin-bottom: 20px;
		max-width: 725px;
	}

	&__icons-list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}

	&__icons-item {
		list-style: none;
		margin-left: 11px;
		margin-right: 11px;
		cursor: pointer;
	}

	&__img {
		max-height: 42px;
	}
}