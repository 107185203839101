.car-in-stock-info {

	&__price-wrapper {
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 15px;
		}
		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&__price {
		margin-bottom: 37px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
			padding-right: 15px;
		}
	}

	&__price-val {
		font-size: 35px;
		line-height: 36px;
		font-weight: 400;
		margin-bottom: 8px;
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
		&--new {
			font-weight: 700;
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
			}
		}
		&--old {
			font-size: 33px;
		}
	}

	&__button {
		@include media-breakpoint-down(sm) {
			padding-left: 15px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0px;
		}
		.more-btn {
			margin-left: 0;
			max-width: 100%;
			padding-top: 10px;
			padding-bottom: 10px;
			margin-top: 10px;
		}
	}

	&__redirect-text {
		font-size: 15px;
		font-family: $font-family-normal;
		margin-top: 13px;
		@include media-breakpoint-down(sm) {
			letter-spacing: 0px;
			margin-top: 10px;
			line-height: 15px;
		}
	}

	&__colors-title {
		font-size: 16px;
		font-family: $font-family-normal;
		margin-bottom: 13px;
	}

	&__img {
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	&__slider {
		@include media-breakpoint-down(sm) {
			min-height: 100%;
		}
	}
}

.fotorama {
	@include media-breakpoint-down(md) {
		height: 545px !important;
	}
	@include media-breakpoint-down(sm) {
		height: 410px !important;
	}
	@include media-breakpoint-down(xs) {
		height: 100% !important;
	}
}

.slider-in-stock {
	height: 500px;
	@include media-breakpoint-down(md) {
		height: 300px;
		.slick-list {
			height: 300px;
		}
		.slick-slide {
			height: 300px;
		}
	}
	.content {
		height: 500px;
		width: 100%;
	}
}

.fotorama {
	opacity: 1 !important;
	&__fullscreen-icon {
		background: url('/files/resources/images/icons/search-icon.png') !important;
		top: 5px !important;
		right: 5px !important;
	}

}

.fotorama > * {
	visibility: hidden;
	height: 0;
}

.fotorama > .fotorama__wrap {
	visibility: visible;
	height: auto;
}

.fotorama--fullscreen {
	.fotorama__fullscreen-icon {
		background: url('/files/resources/images/icons/cancel.png') no-repeat !important;
		background-position: center center;
		top: 5px !important;
		right: 5px !important;
	}
}


.fotorama__nav__frame--thumb {
	width: 154px !important;
	height: 89px !important;
	@include media-breakpoint-down(lg) {
		width: 100px !important;
		height: 58px !important;
	}
	@include media-breakpoint-down(md) {
		width: 75px !important;
		height: 43px !important;
	}
	@include media-breakpoint-down(sm) {
		width: 100px !important;
		height: 58px !important;
	}
	@include media-breakpoint-down(xs) {
		width: 54px !important;
		height: 31px !important;
	}
}

.fotorama__thumb-border {
	display: none;
}

.fotorama__active {
	.fotorama__thumb {
		box-sizing: border-box;
		border: 1px solid #707070 !important;
	}
}

.fotorama__thumb {
	.fotorama__img {
		left: 0px !important;
		width: 100% !important;
        height: 100% !important;
		height: initial !important;
		max-height: 540px;
		@include media-breakpoint-down(lg) {
			max-height: 351px;
		}
		@include media-breakpoint-down(md) {
			max-height: 260px;
		}
		@include media-breakpoint-down(sm) {
			max-height: 357px;
		}
		@include media-breakpoint-down(xs) {
			max-height: 286px;
		}
	}
}

.fotorama__nav__shaft {
	height: 100px;
	@include media-breakpoint-down(lg) {
		height: 70px;
	}
	@include media-breakpoint-down(md) {
		height: 58px;
	}
	@include media-breakpoint-down(sm) {
		height: 70px;
	}
	@include media-breakpoint-down(xs) {
		height: 50px;
	}
}

.fotorama__nav__frame {
	@include media-breakpoint-up(xl) {
		height: 89px;
	}
	@include media-breakpoint-down(xl) {
		height: 89px;
	}
	@include media-breakpoint-down(lg) {
		height: 58px;
	}
}

.fotorama__arr {
	background-color: $accent-color !important;
	width: 21px !important;
	height: 46px !important;
	opacity: 1;
	transition: opacity 0.1s linear;

	&:hover {
		opacity: 0.7;
	}
}

.fotorama__arr--prev {
	left: 0px !important;
}

.fotorama__arr--next {
	right: 0px !important;
}

.fotorama__arr:after, .fotorama__arr:focus:after {
	position: absolute;
	z-index: 1000;
	content: " ";
	border-right: 3px solid #fff;
	border-bottom: 3px solid #fff;
	width: 10px;
	height: 10px;
	margin-right: 10px;
	transform: rotate(-43deg);
}

.fotorama__arr--prev:after, .fotorama__arr--prev:focus:after {
	transform: rotate(131deg);
	top: 39%;
	right: -25%;
}

.fotorama__arr--next:after, .fotorama__arr--next:focus:after {
	top: 39%;
	left: 20%;
}