.about {
	margin-top: 90px;
	font-family: $font-family-normal;
	&__wrapper {
		padding-left: 0px;
	}

	&__title {
		font-family: $font-family;
		font-size: 25px;
		font-weight: 700;
		font-style: italic;
		color: $main-font-color;
		margin-top: 40px;
	}

	&__text {
		margin-top: 15px;
		font-size: 17px;
		color: $main-font-color;
	}
}