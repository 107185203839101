.faq-categories {
	margin-top: 60px;

	&__title {
		@include media-breakpoint-down(sm) {
			font-size: 22px;
		}
	}

	.list-unorder {
		max-width: 50%;
		padding-left: 17px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			max-width: 100%;
			width: 100%;
		}
		li {
			width: 50%;
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
			a {
				color: #000;
				transition: color 0.1s linear;
				&:hover {
					color: $accent-color;
					text-decoration: none;
				}
			}
		}
	}
}