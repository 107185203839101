.single-news {
	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__title {
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	&__text, &__announce {
		font-size: 15px;
	}

	img {
		width: 100%;
		height: auto;
	}

	&__media {
		img {
			width: 100%;
		}
	}

	&__item {
		padding-bottom: 0;
	}

	&__more-news-title {
		margin-bottom: 26px;
		font-family: $font-family;
		text-transform: uppercase;
		font-size: 25px;
		padding-left: 15px;

		@include media-breakpoint-down(md) {
			margin-top: 55px;
		}
	}

	.news-list__item-desc {
		display: block;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		max-height: 4.6em;
		line-height: 1.15rem;
	}

	.news-list__item .row {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.news-list__item-title {
		margin-top: 15px;
		@include media-breakpoint-down(md) {
			font-size: 15px;
		}
	}

	.more-btn {
		position: absolute;
		left: 15px;
		bottom: 0;
		max-width: 285px;
		@include media-breakpoint-down(lg) {
			max-width: 287px;
		}
		@include media-breakpoint-down(md) {
			max-width: 210px;
			left: 15px;
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
			max-width: 100%;
			width: 100%;
			margin-top: 0;
		}
	}

	p {
		.more-btn {
			left: 0;
			position: relative;
			display: inline-block;
			width: auto;
			max-width: unset;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.news__see-all-link {
		right: 15px;
		bottom: auto;
		margin-top: -20px;
	}

	&__more-news--actions {
		.news-list__info-wrapper {
			padding-bottom: 50px;
		}
	}
}