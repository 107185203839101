.faq-main {
	.form_inner_wrapper {
		display: flex;
		div {
			@include media-breakpoint-down(sm) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.item-4 {
			@include media-breakpoint-down(md) {
				order: 5;
				.more-btn {
					max-width: 100%;
				}
			}

			@include media-breakpoint-down(sm) {
				order: 4;
				.more-btn {
					margin-bottom: 14px;
				}
			}
		}

		.item-5 {
			.more-btn {
				max-width: 335px;
			}

			@include media-breakpoint-down(md) {
				order: 4;
				.more-btn {
					max-width: 100%;
				}
			}

			@include media-breakpoint-down(sm) {
				order: 5;
				.more-btn {
					max-width: 100%;
				}
			}
		}

		.like-h3 {
			font-family: $font-family-normal;
			font-size: 22px;
			font-weight: 400;
			border-bottom: 1px solid lightgrey;
			line-height: 26px;
			padding-bottom: 8px;
			margin-bottom: 35px;
		}

		.more-btn {
			margin-top: 0;
			.button-text {
				display: inline;
			}
			.nav-arrow {
				right: -27px;
			}
			.nav-arrow--1 {
				right: -18px;
			}
		}

		.field_wrapper {
			margin-bottom: 14px;
		}

		.button-text {
			background-color: transparent;
			border: none;
			text-transform: uppercase;
		}

		.ik_select {
			top: 0px !important;
		}


		.input_wrapper {
			height: 34px !important;
			max-height: 34px !important;
		}

		.input_wrapper .form-control {
			height: 34px !important;
			line-height: 34px !important;
			font-family: $font-family-normal !important;
			font-size: 15px !important;
			color: #000000;
			background-color: #fff;
			vertical-align: middle;
		}
		.ik_select_dropdown {
			left: 0px !important;
			top: 34px !important;
			width: 100% !important;
		}

	}

	.form-control {
		border: 1px solid #707070 !important;

		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: lightgrey;
			opacity: 1; /* Firefox */
			position: relative;
			top: 2px;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: lightgrey;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: lightgrey;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}
	}

	.safari-placeholder {
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			top: -7px;
		}
	}

	.action-card {
		h2 {
			font-size: 25px;
			line-height: 29px;
			font-family: $font-family-normal;
			font-style: normal;
			font-weight: 400;
			margin-bottom: 0;
		}

		.more-btn {
			margin-top: 20px;
		}
	}

	.ik_select_link_text {
		top: 0px;
	}

	.not_found_text {
		font-size: 22px;
		font-family: $font-family-normal;
		font-weight: 400;
		line-height: 26px;
		margin-top: 48px;
		font-style: normal;
		a {
			color: #000;
			transition: color 0.1s linear;
			text-decoration: underline;
			&:hover {
				color: $accent-color;
			}
		}
	}
}

