.block-table {
	margin-top: 80px;
	h2 {
		text-transform: uppercase;
		font-size: 28px;
	}
}

.table {
	font-family: $font-family;
	font-size: 16px;
	font-weight: 400;
	border-collapse: collapse;
	@include media-breakpoint-down(lg) {
		display: block;
		overflow-x: auto;
		//white-space: nowrap;
	}



	.table-cell-head {
		padding-top: 40px;
		padding-bottom: 20px;
		border: 1px solid lightgrey;
		background-color: rgb(251, 247, 234);
		font-weight: normal;
		padding-left: 18px;
	}
	.table-cell {
		border: 1px solid lightgrey;
		padding-left: 18px;
	}
}

table tbody tr:hover {
	background-color: rgb(251, 243, 242);
	box-shadow: inset 0px 0px 4px $accent-color;
	-webkit-box-shadow: inset 0px 0px 4px $accent-color;
	-moz-box-shadow: inset 0px 0px 4px $accent-color;
}
table tbody tr:hover td {
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
}

th:first-child {
	vertical-align: middle;
}