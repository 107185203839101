.lists {
	margin-top: 80px;
	h2 {
		text-transform: uppercase;
		color: #333;
	}
}

.list-unorder {
	margin-bottom: 30px;
	li {
		font-family: $font-family-normal;
		list-style: none;
		position: relative;
		color: #000;
		font-size: 18px;
		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			display: block;
			height: 15px;
			width: 15px;
			background-color: red;
		}
		ul {
			margin-top: 35px;
			margin-bottom: 35px;
			li {
				color: #858585;
			}
			li:before {
				background: #858585;
			}
		}
	}

	li:before {
		content: '';
		position: absolute;
		top: 6px;
		left: -17px;
		display: block;
		width: 10px;
		height: 10px;
		background: $accent-color;
		border-radius: 10px 0 0 0;
		-moz-border-radius: 10px 0 0 0;
		-webkit-border-radius: 10px 0 0 0;
	}
}

ol {
	counter-reset: item;
	li {
		display: block;
		font-family: $font-family-normal;
		margin-bottom: 5px;
		position: relative;
		font-size: 18px;
	}
	li:before {
		content: counters(item, ".") " ";
		counter-increment: item;
		position: absolute;
		width: 21px;
		height: 21px;
		left: -27px;
		top: 1px;
		font-size: 13px;
		background: $accent-color;
		border-radius: 100%;
		color: white;
		text-align: center;
		display: inline-block;
		padding-top: 1px;
		@include media-breakpoint-down(lg) {
			content: counters(item, ".") ".";
			background: transparent;
			color: inherit;
			font-size: inherit;
			top: 0;
			list-style: initial;
			padding-top: 0;
		}
	}

	.sublist {
		margin-top: 10px;
		li {
			color: #858585;
		}
		li:before {
			position: absolute;
			width: 21px;
			height: 21px;
			left: -27px;
			top: 1px;
			font-size: 11px;
			background: #858585;
			border-radius: 100%;
			color: white;
			text-align: center;
			display: inline-block;
			padding-top: 2px;
			padding-left: 1px;
			@include media-breakpoint-down(lg) {
				background: transparent;
				color: inherit;
				font-size: inherit;
				top: 0;
				left: -35px;
				padding-top: 0;
			}
		}
	}
}

.approved-wrapper  {
	.list-unorder {
		padding-left: 0;
		li {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			&:before {
				display: none;
			}

			img {
				margin-right: 10px;
			}
		}
	}
}