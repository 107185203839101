.text-with-link {
	padding-top: 80px;
	.wrapper {
		position: relative;
	}
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
	p {
		font-size: 17px;
		margin-bottom: 0;
	}
}

.see-all-link {
	font-size: 20px;
	font-weight: 700;
	font-style: italic;
	color: $main-font-color;
	text-decoration: underline;
	text-transform: uppercase;
	transition: color 0.1s linear;
	position: absolute;
	right: 28px;
	bottom: -60px;
	&:hover {
		color: $accent-color;
	}
}