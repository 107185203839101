.soft-404 {
	background-color: #FFCCCC;
	margin-bottom: 30px;

	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	&__text {
		font-size: 15px;
		font-family: $font-family-normal;
		font-weight: 700;
		margin-bottom: 0;
		padding-top: 15px;
		padding-bottom: 15px;
		display: inline-block;
		margin-left: 15px;
	}
}

.soft-404--action {
	a {
		color: #000;
	}

	.soft-404__text {
		margin-left: 0px;
		width: 100%;
		text-align: center;
	}
}