.faq-main {
	.faq_h1 {
		font-size: 40px;
		font-family: $font-family;
		font-style: italic;
		line-height: 52px;
		margin-bottom: 23px;
	}

	.tabs-block {
		overflow-x: auto;
	}

	.item-1 {
		padding-left: 0 !important;
	}

	.item-2 {
		padding-left: 0 !important;
	}

	.item-5 {
		padding-left: 0 !important;
	}

	.special_add_faq_button {
		background-color: $accent-color;
		text-transform: uppercase;

	}

	.more-btn {
		margin-top: 0px;
	}

	.results-title {
		font-family: $font-family-normal;
		font-size: 22px;
		font-weight: 400;
		line-height: 26px;
		font-style: normal;
		margin-top: 30px;
	}

	.found_word {
		background-color: $accent-color;
		color: #fff;
	}

	.tabs-block .tabs {
		@include media-breakpoint-down(md) {
			.tabs-item {
				margin-bottom: 10px;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-wrap: nowrap !important;

			.tabs-item {
				margin-right: 10px;
			}
		}
	}

	.search_faq_form {
		margin-top: 30px;
	}
}