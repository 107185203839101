.accordion {
	margin-bottom: 40px;
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
	a {
		text-decoration: none;
		color: inherit;
	}

	p {
		font-size:1.1em;
		margin: 1em 0;
	}

	.description {
		margin: 1em auto 2.25em;
	}

	&__more-link {
		font-size: 16px;
		font-family: $font-family-normal;
		text-decoration: underline !important;
		transition: color 0.1s linear;
		&:hover {
			color: $accent-color;
			text-decoration: underline;
		}
	}

	body {
		width: 40%;
		min-width: 300px;
		max-width: 400px;
		margin: 1.5em auto;
		color: #333;
	}

	ul {
		list-style: none;
		padding: 0;

		.inner {
			list-style: none;
			padding-left: 45px;
			padding-right: 60px;
			padding-bottom: 30px;
			overflow: hidden;
			display: none;
			background-color: #F9F9F9;
			@include media-breakpoint-down(md) {
				padding-left: 40px;
				padding-right: 40px;
			}
			@include media-breakpoint-down(xs) {
				padding-left: 20px;
				padding-right: 20px;
			}

			&.show {
				/*display: block;*/
			}

			li {
				padding-top: 10px;
				padding-bottom: 8px;
			}
			li:not(:last-child) {
				border-bottom: 1px solid rgb(132, 132, 132);
			}
		}

		li {
			margin: .5em 0;

			.toggle-icon {
				position: relative;
				display: inline-block;
				width: 45px;
				height: 45px;
				background-color: #F0AC01;
				margin-right: 20px;
				font-size: 40px;
				color: #fff;
				font-weight: 700;
				font-style: normal;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding-bottom: 5px;
				&:before {
					position: absolute;
					content: '+';
					font-size: 40px;
					font-weight: 400;

				}
			}
			a.toggle {
				display: flex !important;
				flex-direction: row;
				align-items: center;
				text-transform: uppercase;
				font-family: $font-family;
				font-size: 16px;
				line-height: 12px;
				font-style: italic;
				font-weight: 700;
				width: 100%;
				display: block;
				border: 1px solid lightgrey;
				color: $main-font-color;
				padding: 0;
				transition: background 0.1s ease;
				vertical-align: middle;
				@include media-breakpoint-down(xs) {
					font-size: 13px;
					line-height: 15px;
				}
				&:hover {
					background: lightgrey;
				}
			}
		}
	}
	.open {
		background-color: lightgrey;
	}
	a.toggle.open {
		.toggle-icon {
			&:before {
				content: '-';
			}
		}
	}
	.option-name {
		display: inline-block;
		width: 38%;
		@include media-breakpoint-down(md) {
			font-size: 14px;
			width: 80%;
		}
	}
	.option-value {
		display: inline-block;
		width: 50%;
		@include media-breakpoint-down(md) {
			font-size: 14px;
			width: 15%;
		}
	}



	//таблица внутри аккордеона

	.table {
		width:100%;
		table-layout: fixed;
		overflow-wrap: break-word;
		font-family: $font-family;
		font-size: 16px;
		font-weight: 400;
		border-collapse: collapse;
		display: table;
		overflow-x: auto;
		@include media-breakpoint-down(md) {
			display: block;
		}
		.table-row {
			&:last-child {
				border-bottom: 1px solid transparent;
			}
		}
		.table-cell {
			font-weight: 400;
			border: none;
			border-bottom: 1px solid lightgrey;
			padding-left: 0px;
			min-width: 70px;
			&:first-child {
				padding-left: 15px;
				min-width: 250px;
				max-width: 250px;
				word-break: break-all;
			}
		}
	}

	table tbody tr:hover {
		background-color: rgb(251, 243, 242);
		box-shadow: inset 0px 0px 4px transparent;
		-webkit-box-shadow: inset 0px 0px 4px transparent;
		-moz-box-shadow: inset 0px 0px 4px transparent;
	}
	table tbody tr:hover td {
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
	}

	th:first-child {
		vertical-align: middle;
	}
}


