.block-news-list {
	&__wrapper {

	}
	&__title {
		text-transform: uppercase;
	}
}

.news-list {
	padding-left: 0;

	&__item {
		list-style: none;
		font-family: $font-family-normal;
		margin-bottom: 45px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 37px;
		}
	}

	&__img-wrapper {
		width: 100%;
		img {
			width: 100%;
			max-height: 100%;
		}
	}

	&__info-wrapper {
		position: relative;
		padding-bottom: 30px;
		@include media-breakpoint-down(lg) {
			padding-bottom: 50px;
		}
		@include media-breakpoint-down(sm) {
			margin-left: 0;
			margin-top: 15px;
			padding-bottom: 0;
		}
		.more-btn {
			margin-left: 0;
			position: absolute;
			bottom: 0;
			left: 15px;
			padding-top: 10px;
			padding-bottom: 10px;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
				margin-top: 15px;
				position: relative;
				max-width: 100%;
				left: 0;
			}
		}
	}

	&__item-link {
		text-decoration: none;
		display: block;
		&:hover {
			text-decoration: none;
			color: $accent-color;
		}
	}

	&__item-title {
		margin-bottom: 8px;
		font-style: normal;
		font-family: $font-family;
	}

	&__item-desc {
		font-size: 15px;
		line-height: 18px;
		margin-bottom: 10px;
	}
	
	&__item-date {
		font-size: 15px;
		color: #B8B8B8;
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}
}