@media only screen and (max-width: 992px) {

	.smoke_features_container {
		a {
			img {
				display: block !important;
			}
		}
	}
}

.img-wrapper {
	max-width: 100%;

}

#mw-video-center {
	a.mw-aerothumbs .highlighter-box {
		width: 90px;
		height: 51px;
	}

	a.mw-aerothumbs {
		height: 53px;
	}
}

#additional {

	.hide-all--add {
		display: none;
	}

	#list-notes {
		height: 0;
		opacity: 0;
	}

	.show-all--add, .hide-all--add {
		background: none;
		border: none;
		font-size: 16px;
		font-family: $font-family-normal;
		text-decoration: underline;
		margin-left: 20px;
		position: relative;
		z-index: 2;
		&:hover {
			text-decoration: none;

		}
	}

	.disclaimer {
		position: relative;
		z-index: 1;
	}
}

.content-to-hide {
	height: 0;
	opacity: 0;
}

.hide-content {
	display: none;
}

.show-content, .hide-content {
	background: none;
	border: none;
	font-size: 16px;
	font-family: $font-family-normal;
	text-decoration: underline;
	margin-left: 20px;
	position: relative;
	z-index: 2;
	margin-top: 20px;
	margin-bottom: 40px;
	&:hover {
		text-decoration: none;

	}
}

.gallery-links {
	margin-bottom: 15px;
	.links-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		.more-btn {
			max-width: 49%;
			width: 100%;
			margin-top: 0px;

			@include media-breakpoint-down(xs) {
				max-width: 100%;

				.nav-arrow {
					display: none;
				}

				.button-text {
					left: 0;
				}
			}
		}
		.more-btn--active {
			text-decoration: none;
			color: $main-font-color;
			background-color: #fff;
			&:hover {
				background-color: #F5C626;
				color: #fff;
				.button-text {
					.nav-arrow {
						border-color: #fff;
					}
				}
			}
			.button-text {
				.nav-arrow {
					border-color: #000;
					transition: border-color 0.1s linear;
				}
			}
		}
	}
}

.model-list-container {
	margin-top: 0px;
}

.model-actions-title-wrapper {
	margin-top: 40px;
}

.model-actions-wrapper {
	margin-bottom: 70px;
	h2 {
		display: none;
	}
}

.complectations-wrapper {
	.figure {
		@include media-breakpoint-down(lg) {
			flex-wrap: wrap;

			img {
				order: 1;
			}

			ul {
				order: 3;
				margin-top: 30px;
			}

			.price-button-wrapper {
				order: 2;
				width: 50%;
				align-items: flex-end;
			}
		}
		@include media-breakpoint-down(md) {
			.list-unorder {
				padding-left: 20px;
				padding-right: 0px !important;
			}

          .price-button-wrapper {
            order: 2;
            width: 100%;
            align-items: flex-start;
            .button-wrapper {
              width: 50%;
            }
            .more-btn {
              max-width: 100% !important;
            }
          }
		}
		@include media-breakpoint-down(sm) {
			.price-button-wrapper {
				align-items: flex-start;
				width: 100%;

				.button-wrapper {
					width: 100%;
					.more-btn {
						max-width: 100% !important;
					}
				}
			}
		}
	}
}

.features_container {
	img {
		max-width: 100% !important;
	}
}

.model-gallery-container {
	margin-bottom: 40px;
	.fotorama__stage__frame {
		img {
			height: 100% !important;
			width: auto !important;
			top: 0 !important;
		}
	}
}

.buttons-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 70px;
	padding-left: 0;
	padding-right: 0;
	@include media-breakpoint-down(sm) {
		margin-right: 0;
		margin-left: 0;
	}

	.more-btn {
		max-width: 23%;
		@include media-breakpoint-down(lg) {
			max-width: 45%;
		}
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}
}