.promo {
	height: 500px;
	position: relative;
	z-index: 9;
	@include media-breakpoint-down(md) {
		padding-top: 60px;
		height: 425px;
	}
	@include media-breakpoint-down(sm) {
		height: 365px;
	}
	@include media-breakpoint-down(xs) {
		height: 220px;
	}
	
	.slider {
		margin-bottom: 0 !important;
		@include media-breakpoint-down(lg) {
			height: 400px;
		}
		@include media-breakpoint-down(md) {
			height: 365px;
		}
		@include media-breakpoint-down(sm) {
			height: 295px;
		}
		@include media-breakpoint-down(xs) {
			height: 263px;
		}
		.slick-list {
			margin: 0 0px;
			height: 500px;
			@include media-breakpoint-down(lg) {
				height: 500px;
			}
			@include media-breakpoint-down(md) {
				height: 365px;
			}
			@include media-breakpoint-down(sm) {
				height: 295px;
			}
			@include media-breakpoint-down(xs) {
				height: 263px;
			}
			.slick-slide {
				margin: 0 0px;
				width: 100%;
				height: 500px;
				max-width: 1200px;
				position: relative;
				@include media-breakpoint-down(lg) {
					max-width: 1100px;
					height: 500px;
				}
				@include media-breakpoint-down(md) {
					max-width: 900px;
					height: 500px;
				}
				@include media-breakpoint-down(sm) {
					max-width: 600px;
					height: 295px;
				}
				@include media-breakpoint-down(xs) {
					max-width: 360px;
					height: 175px;
				}
				.slider-item {
					width: 100%;
					transform: skew(-27deg);
					overflow: hidden;
					position: relative;
					height: 500px;

					&:hover > .content {
						transform: skew(27deg);
					}
					.content {
						transform: skew(27deg);
						position: absolute;
						width: 130%;
						min-height: 100%;
						left: -15%;
						background-position: center center;
						@include media-breakpoint-down(lg) {
							left: -12%;
						}

						@include media-breakpoint-down(md) {
							-webkit-background-size: cover;
							background-size: cover;
						}
						@include media-breakpoint-down(xs) {
							left: -9%;
							width: 130%
						}
					}
					@include media-breakpoint-down(md) {
						height: 365px;
						width: 103%;
					}
					@include media-breakpoint-down(sm) {
						height: 295px;
					}
					@include media-breakpoint-down(xs) {
						height: 182px;
					}
				}

			}
		}
		.line {
			position: absolute;
			width: 15px;
			height: 500px;
			background-color: #fff;
			z-index: 10;
			top: 0;
			transform: rotate(0deg);
			@include media-breakpoint-down(md) {
				height: 365px;
			}
			@include media-breakpoint-down(sm) {
				height: 295px;
			}
			@include media-breakpoint-down(xs) {
				height: 263px;
			}
		}
		.slick-active {
			position: relative;
			z-index: 9;
			.line {
				display: none;
			}
		}
		div .prev-slide-item {
			position: relative;
			left: 0px;
			z-index: 10;
			transition: left 0.3s linear;
			&:hover {
				left: 20px;
			}
			.line {
				right: 0px;
			}
		}

		div .next-slide-item {
			position: relative;
			left: 0px;
			z-index: 10;
			transition: left 0.3s linear;
			&:hover {
				left: -20px;
			}
			.line {
				left: 0px;
			}
		}
	}
	.slick-dots {
		position: relative;
		top: -50px;
		text-align: center;
		li {
			list-style: none;
			opacity: 0.5;
			height: 15px;
			width: 15px;
			background-color: #fff !important;
			border-radius: 50%;
			margin: 0 10px;
			button {
				outline: none;
				&:before {
					display: none;
				}
			}
		}
		.slick-active {
			opacity: 0.8;
		}
		@include media-breakpoint-down(md) {
			display: none !important;
		}
	}
	.nav-list {
		margin-top: -40px;
		position: absolute;
		top: 50%;
		width: 100%;
		@include media-breakpoint-down(md) {
			margin-top: -25px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 0px;
		}

		li {
			list-style: none;
			position: absolute;
			color: #cdcbcd;
			width: 30px;
			height: 80px;
			background-color: #fff !important;
			opacity: 0.8;
			font-size: 30px !important;
			line-height: 60px !important;
			text-align: center;
			&:hover {
				opacity: 0.5;
			}
			.nav-arrow {
				position: absolute;
				top: 36px;
				display: inline-block;
				border-right: 3px solid #000;
				border-bottom: 3px solid #000;
				width: 10px;
				height: 10px;
				margin-right: 10px;
			}
		}
		.prev {
			left: 0px;
			.nav-arrow {
				transform: rotate(130deg);
				right: 0px;
			}
		}
		.next {
			right: 0px;
			.nav-arrow {
				transform: rotate(-46deg);
				right: 0px;
			}
		}
	}
}

@keyframes appear  {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.know-more-btn {
	z-index: 11;
	height: 80px;
	width: 250px;
	position: absolute;
	top: 360px;
	right: 5%;
	background-color: #fff;
	color: $main-font-color;
	transition: color 0.1s linear;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s linear;

	&:hover {
		.know-more-btn__text {
			color: $accent-color;
		}
		.nav-arrow {
			border-color: $accent-color;
		}
	}
	@include media-breakpoint-down(lg) {
		top: 380px;
		right: 3%;
	}
	@include media-breakpoint-down(md) {
		top: 250px;
		right: 4%;
	}
	@include media-breakpoint-down(sm) {
		top: 190px;
		height: 50px;
		width: 180px;
		right: 10%;
	}
	@include media-breakpoint-down(xs) {
		top: 130px;
		height: 30px;
		width: 150px;
		right: 12%;
	}
	&__content {
		transform: skew(27deg) !important;
	}
	&__text {
		font-family: $font-family;
		letter-spacing: 0.5px;
		font-size: 17px;
		text-transform: uppercase;
		position: relative;
		margin-right: 27px;
		@include media-breakpoint-down(sm) {
			font-size: 14px;
			line-height: 14;
		}
		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}
		.nav-arrow {
			position: absolute;
			top: 7px;
			right: -36px;
			display: inline-block;
			border-right: 2px solid;
			border-bottom: 2px solid;
			border-color: #000;
			transition: border-color 0.1s linear;
			width: 9px;
			height: 9px;
			transform: rotate(-46deg);
			margin-right: 10px;
			&--1 {
				right: -23px;
			}
			@include media-breakpoint-down(sm) {
				display: block;
				top: 5px;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}
}
