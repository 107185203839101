.traverse-container {
  .standard-gallery-traverse-top {
    max-height: 500px;
    overflow: hidden;
    animation: appear 1s;
    @include media-breakpoint-down(xl) {
      .nav-list {
        margin-top: -58px;
      }
      .slick-slider {
        height: auto !important;
        margin-bottom: -47px;
        .slick-list {
          height: auto !important;
          .slick-slide {
            height: auto !important;
            .slider-item-standard {
              height: auto !important;
              .content-standard {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .slick-dots {
      top: -67px !important;
    }
  }

  .standard-gallery-traverse-top--2 {
    max-height: 595px !important;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      .slick-slide {
        img {
          position: relative !important;
          top: -7px !important;
        }
      }
    }
  }

  @keyframes appear  {
    0% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }
    50% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }

    99% {
      opacity: 0.7;
      height: 500px;
      overflow: hidden;
    }

    100% {
      opacity: 1;
      overflow: unset;
      height: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    .features_container {
      .more-btn {
        max-width: 100% !important;
      }
    }
  }

  .slick-list {
    height: 600px;
  }

  strong {
    font-weight: 400 !important;
  }

  .slider-standard .slick-list .slick-slide .slider-item-standard .content-standard {
    background-repeat: no-repeat;
    -webkit-background-size: auto;
    background-size: auto;

    @include media-breakpoint-down(lg) {
      -webkit-background-size: cover;
      background-size: cover;
    }
  }

  .fw_win_title {
    min-height: 50px !important;
    height: 100px !important;
  }

  .videos-wrapper {
    margin-left: -10px;
    margin-right: -10px;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .video-container {
    margin-right: 10px;
    margin-left: 10px;
    flex-basis: 30% !important;
  }

  .video-text {
    display: block;
  }

  .image-for-video {
    width: 100%;
  }
}

.traverse-gallery-container {
  .fotorama {
    @include media-breakpoint-down(md) {
      height: auto !important;
    }

  }


  .fotorama__thumb {
    img {
      height: 100% !important;
      width: initial !important;
      //left: -20px !important;
    }
  }
}

.gallery-links--traverse {
  @include media-breakpoint-down(md) {
    top: -121px;
  }
  @include media-breakpoint-down(sm) {
    top: -133px;
  }
  @include media-breakpoint-down(xs) {
    top: -113px;
  }
}