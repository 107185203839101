.wrapper-banner .image{
	width: 100%;
}

.wrapper-search{
	margin: 25px 0;
	.select-wrapper {
		display: block;
		position: relative;
		@include media-breakpoint-down(xl) {
			max-width: 570px;
		}
		@include media-breakpoint-down(lg) {
			max-width: 428px;
		}
		@include media-breakpoint-down(md) {
			max-width: 315px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}

		.ik_select_dropdown {
			left: auto !important;
			top: auto !important;
			width: 570px !important;
		}

		.ik_select {
			padding: 0 !important;
			width: 100% !important;
		}

		.ik_select_link {
			-webkit-border-radius: 0px !important;
			-moz-border-radius: 0px !important;
			border-radius: 0px !important;
			font-family: $font-family-normal !important;
			padding-left: 21px !important;
			padding-right: 21px !important;
			position: relative !important;
			width: 100% !important;
			max-width: 100% !important;
		}

		.ik_select_link_text {
			font-size: 15px !important;
			color: #000 !important;
			padding-bottom: 8px !important;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
	}

	//select::-ms-expand {
	//	display: none;
	//}

	.filter {
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		max-width: 340px;
		font-family: $font-family-normal;
		padding-left: 21px;
		padding-right: 21px;
		position: relative;
		width: 100%;
		max-width: 100%;
	}

	select.division {
		font-size: 15px !important;
		color: #000 !important;
		padding-top: 7px !important;
		padding-bottom: 8px !important;
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		&:hover {
			background: #E4E4E4 !important;
			border: none !important;
			box-shadow: none !important;
		}
	}
}
.wrapper-search .title{
	display: block;
	font-weight: 900;
	margin-bottom: 20px;
	font-family: $font-family-normal;
}

.list-manager{
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;
	margin: 0 -10px;
	@include media-breakpoint-down(md) {
		.phone, .email {
			font-size: 13px;
		}
	}
	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;
		.phone, .email {
			font-size: 17px;
		}
	}
	@include media-breakpoint-down(xs) {
		display: flex;
		flex-direction: column;
		.phone, .email {
			font-size: 14px;
		}

		.contact-content {
			position: relative;
			left: -10px;
		}
	}
}
.list-manager .column{
	flex-basis: 47.2%;
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
}
.list-manager .column .person-card{
	border: 1px solid lightgrey;
	height: 100%;
	font-family: $font-family-normal;
}
.list-manager .person-card .row{
	display: flex;
	flex-wrap: nowrap;
	height: inherit;
}
.list-manager .person-card .image-content{
	display: inline-block;
	width: 150px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.list-manager .person-card .contact-content{
	display: inline-block;
	padding-top: 15px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;

	@include media-breakpoint-down(md) {
		padding-left: 0px;
		padding-right: 3px;
	}
}
.person-card .contact-content .name{
	font-size: 14px;
	font-weight: 900;
	padding-bottom: 5px;
}
.person-card .contact-content .position{
	font-size: 11px;
	padding-bottom: 11px;
}
.person-card .contact-content .division{
	font-size: 11px;
	font-weight: 900;
	padding-top: 8px;
}
.column .ribbon {
	position: absolute;
	font-size: 9px;
	font-weight: 900;
	text-transform: uppercase;
	background: #fcbc29;
	padding: 2px 5px 2px 18px;
	color: #fff;
	margin-top: -15px;
	margin-left: -7px;
	background-image: url(/images/star-background.png);
	background-repeat: no-repeat;
	background-position-x: 7px;
	background-position-y: 5px;
	background-size: 10px;
	white-space: nowrap;
}
.column .ribbon::before{
	content: ' ';
	position: absolute;
	top: -6px;
	left: 0;
	border-style: solid;
	border-width: 0 0 6px 8px;
	border-color: transparent transparent #c99720 transparent;
}