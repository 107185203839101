.model-specs {
	margin-top: 25px;

	&__list {
		padding-left: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			justify-content: space-between;
		}
	}

	&__item {
		padding-top: 5px;
		padding-bottom: 10px;
		margin-bottom: 30px;
		list-style: none;
		flex: 1;
		min-width: 195px;
		&:not(:last-child) {
			border-right: 1px solid #D2CFCF;
		}
		@include media-breakpoint-down(lg) {
			max-width: 232px;
			&:not(:last-child) {
				border-right: 1px solid transparent;
			}
		}
		@include media-breakpoint-down(lg) {
			max-width: 195px;
		}
		@include media-breakpoint-down(md) {
			min-width: 170px;
			max-width: 170px;
		}
		@include media-breakpoint-down(md) {
			&:nth-child(4) {
				padding-left: 0px;
			}
		}
		@include media-breakpoint-down(xs) {
			min-width: 130px;
			max-width: 43%;
			margin-bottom: 20px;
			padding-left: 0 !important;
		}
	}

	&__item-title {
		font-family: $font-family-normal;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 5px;
		@include media-breakpoint-down(md) {
			font-size: 14px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 11px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 13px;
		}
	}

	&__item-value {
		font-family: $font-family-normal;
		font-size: 25px;
		line-height: 25px;
		margin-bottom: 0px;
		margin-top: 0;
		@include media-breakpoint-down(md) {
			font-size: 22px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 18px;
		}
		&--price {
			font-weight: 700;
			@include media-breakpoint-down(md) {
				font-weight: 400;
			}
		}
	}

	&__item-link {
		font-size: 13px;
		color: #ABABAB;
		text-decoration: underline;
		transition: color 0.1s linear;
		display: block;
		width: 100%;
		text-align: center;
		@include media-breakpoint-down(md) {
			font-size: 11px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 10px;
		}
		&:hover {
			color: $accent-color;

		}
	}
}

.car-in-stock-info {
	&__specs {
		.model-specs {
			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.model-specs__list {
			margin-bottom: 0;
		}

		.model-specs__item {
			border-right: 1px solid transparent;
			margin-bottom: 13px;
			padding-left: 0;

			@include media-breakpoint-down(lg) {
				min-width: 170px !important;
			}
			@include media-breakpoint-down(md) {
				min-width: 135px !important;
			}
			@include media-breakpoint-down(sm) {
				min-width: unset !important;
				text-align: center;
			}
			@include media-breakpoint-down(xs) {
				min-width: 50% !important;
				text-align: left;
			}
		}

		.model-specs__item-title {
			@include media-breakpoint-down(sm) {
				font-size: 11px;
			}
			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}
		}
		.model-specs__item-value {
			@include media-breakpoint-down(sm) {
				font-size: 21px;
			}
		}

		.model-specs__item-link {
			display: none;
		}
	}
}

.model-specs--taho {

	.model-specs__item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.model-specs__value-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 40px;
		align-items: center;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.model-specs__img {
		margin-right: 10px;
	}
}

.model-specs--traverse {

	.model-specs__item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.model-specs__value-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 40px;
		align-items: center;
		margin-top: 5px;
		margin-bottom: 5px;
		padding-left: 10px;
		padding-right: 10px;

		@include media-breakpoint-down(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.model-specs__img {
		margin-right: 10px;
	}
}