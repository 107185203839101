.used-list {
	.tabs-block {
		.tabs {
			@include media-breakpoint-down(lg) {
				flex-wrap: wrap;
			}
			@include media-breakpoint-down(sm) {
				overflow-x: auto;
				flex-direction: row !important;
				flex-wrap: nowrap;
				justify-content: flex-start;
			}
		}
	}
	.tabs-item {
		//font-size: 17px !important;
		@include media-breakpoint-down(lg) {
			min-width: unset;
			width: auto !important;
		}
		@include media-breakpoint-down(sm) {
			min-width: 120px;
		}
		@include media-breakpoint-down(xs) {
			margin-right: 15px !important;
		}
		//a {
		//	padding-top: 12px !important;
		//	padding-bottom: 12px !important;
		//}
	}

	.tab_content {
		overflow-x: hidden;
	}

	.cars__list {
		margin-right: -20px !important;
		margin-left: -20px !important;
	}

	.car-item-used__price {
		margin-bottom: 0;
	}

	.car-item-used {
		max-width: 16%;
		box-shadow: none;
		list-style: none;
		margin-right: 23px;
		margin-left: 23px;
		@include media-breakpoint-down(lg) {
			width: 20%;
			max-width: 20%;
		}
		@include media-breakpoint-down(md) {
			width: 27%;
			max-width: 27%;
			margin-right: 20px;
			margin-left: 20px;
		}
		@include media-breakpoint-down(sm) {
			width: 42%;
			max-width: 42%;
		}
		@include media-breakpoint-down(xs) {
			max-width: 100%;
			width: 100%;
		}

		&__wrapper {
			&:hover {
				text-decoration: none;

				.car-item-used__price-block, .car-item-used__title, .car-item-used__spec {
					opacity: 0.8;
				}
			}

		}

		&__img {
			width: 100%;
			max-height: 135px;
			@include media-breakpoint-down(lg) {
				height: 130px;
				max-height: 130px;
			}
			@include media-breakpoint-down(md) {
				height: 157px;
				max-height: 157px;
			}
			@include media-breakpoint-down(md) {
				height: 135px;
				max-height: 135px;
			}
			@include media-breakpoint-down(xs) {
				height: auto;
				max-height: none;
			}
		}

		&__title {
			font-size: 20px;
			font-family: $font-family-normal;
			font-style: normal;
			margin-top: 10px;
			margin-bottom: 0;
			opacity: 1;
			transition: opacity 0.1s linear;
			@include media-breakpoint-down(lg) {
				font-size: 18px;
				margin-bottom: 5px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 19px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}

		&__spec {
			opacity: 1;
			transition: opacity 0.1s linear;
		}

		&__spec-item {
			font-size: 12px;
			margin-top: 0;
			margin-bottom: 5px;
			@include media-breakpoint-down(lg) {
				font-size: 11px;
				margin-bottom: 8px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 10px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11px;
			}
			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}
		}

		&__price {
			font-size: 14px;
			font-weight: 700;
			color: $main-font-color;
			margin-bottom: 0;
			opacity: 1;
			transition: opacity 0.1s linear;
			@include media-breakpoint-down(lg) {
				font-size: 12px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}
			@include media-breakpoint-down(xs) {
				font-size: 20px;
			}
		}

		&__price-new {
			font-weight: 700;
			color: #FF6515;
			@include media-breakpoint-down(lg) {
				font-size: 12px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11px;
			}
			@include media-breakpoint-down(xs) {
				font-size: 20px;
			}
		}
	}
}