.header {
	box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.11);
	position: relative;
	z-index: 20;
	font-family: $font-family-normal;
	@include media-breakpoint-down(md) {
		position: absolute;
		width: 100%;
		background: #fff;
	}

	@media screen and (max-width: 769px) and (orientation: portrait) {
		position: fixed;
		top: 0;
		transition: top 0.3s linear;
	}

	&__container {
		position: relative;
		z-index: 19;
		@media (min-width: $grid-breakpoints-lg) and (max-width: $grid-breakpoints-xl) {
			max-width: 100% !important;
		}

		@include media-breakpoint-down(sm) {
				max-height: 90vh;
				overflow-y: auto;
				overflow-x: hidden;
			}
	}
	.navbar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 0;
		background-color: #fff !important;

		.navbar-brand {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			@include media-breakpoint-down(md) {
				margin-right: 0;
				flex: none;
			}
		}
		.navbar-toggler[aria-expanded="true"] {
			border: none;
			outline: none;
			padding: 0;
			.navbar-toggler-icon {
				background-image: url("/../../files/resources/images/close-button.png") !important;
			}
		}

		.navbar-toggler[aria-expanded="false"] {
			border: none;
			outline: none;
			padding: 0;
			.navbar-toggler-icon {
				background-image: url("/../../files/resources/images/menu-button.png") !important;
			}
			#overlay {
				display: none;
			}
		}

		.navbar-collapse {
			&__wrapper {
				display:-webkit-box;
				display:-ms-flexbox;
				display:flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-ms-flex-pack: distribute;
				justify-content: space-around;
				-webkit-box-flex: 14;
				-ms-flex: 14;
				flex: 14;
				margin-left: 30px;
				-ms-margin-left: 80px;
				@include media-breakpoint-down(lg) {
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;
				}
				@include media-breakpoint-down(md) {
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					margin-left: 0;
				}

				.navbar-nav {
					padding-left: 0;
					padding-top: 21px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;
					-webkit-box-flex: 8;
					-ms-flex: 8;
					flex: 8;
					@media (min-width: $grid-breakpoints-lg) and (max-width: $grid-breakpoints-xl) {
						flex: none;
					}
					@media(min-width: $grid-breakpoints-lg) and (max-width: 1060px){
						margin-left: -42px;
						margin-right: 7px;
					}
					@include media-breakpoint-down(md) {
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						-webkit-box-ordinal-group: 3;
						-ms-flex-order: 2;
						order: 2;
						padding-bottom: 50px;
					}

					.nav-item {
						list-style: none;
						font-size: 12px;
						text-transform: uppercase;
						position: relative;
						@media (min-width: $grid-breakpoints-lg) and (max-width: $grid-breakpoints-xl) {
							margin-left: 11px;
							margin-right: -3px;
							&:first-of-type{
								margin-left: 0;
							}
						}
						@include media-breakpoint-down(md) {
							font-size: 13px;
							font-weight: 700;
							font-style: italic;
						}
						&:before {
							position: absolute;
							content: '/';
							color: $accent-color;
							top: 2px;
							right: -16px;
							font-weight: bold;
							font-size: 22px;
							@include media-breakpoint-down(lg) {
								right: -16px;
							}
							@include media-breakpoint-down(md) {
								display: none;
							}
						}
						&:not(:last-child) {
							&:before {
								position: absolute;
								content: '/';
								color: $accent-color;
								top: 2px;
								right: -5px;
								font-weight: bold;
								font-size: 22px;
								@include media-breakpoint-down(lg) {
									right: -8px;
								}
								@include media-breakpoint-down(md) {
									display: none;
								}
							}
						}
						&:last-child {
							&:before {
								display: none;
							}
							.dropdown-menu {
								left: -195px;
							}
						}
						@include media-breakpoint-up(lg) {
							.dropdown-menu__hidden{
								display: none;
							}
						}
					}
					.nav-link {
						color: $main-font-color;
						//transition: color 0.1s linear;
						position: relative;
						display: inline-block;
						@include media-breakpoint-down(md) {
							padding-right: 28px;
						}
						&:after {
							display: none;
						}
						&:before{
							content: '';
							position: absolute;
							display: block;
							right: 5px;
							top: 10px;
							width: 9px;
							height: 9px;
							border-bottom: 1px solid black;
							border-right: 1px solid black;
							transform: rotate(45deg);
							@include media-breakpoint-up(lg) {
								content: none;
							}
						}
						&[aria-expanded="true"]{
							&:before{
								top: 16px;
								transform: rotate(-135deg);
							}
						}
						&.arrow-hidden:before{
							content: none;
						}
						&:hover {
							color: $accent-color;
							text-decoration: none;
							.nav-arrow {
								border-color: $accent-color;
							}
							&:before{
								border-color: $accent-color;
							}
						}
						&:active {
							color: $accent-color;
							text-decoration: none;
							.nav-arrow {
								border-color: $accent-color;
							}
						}
						&:focus {
							color: $accent-color;
							text-decoration: none;
							.nav-arrow {
								border-color: $accent-color;
							}
						}
						&.none-arrow{
							&:before{
								content: none;
							}
						}
						.nav-arrow {
							position: absolute;
							top: 11px;
							right: -25px;
							display: inline-block;
							border-right: 2px solid black;
							border-bottom: 2px solid black;
							width: 9px;
							height: 9px;
							transform: rotate(43deg);
							display: none;
							&--open {
								transform: rotate(224deg);
								top: 16px;
							}
							@include media-breakpoint-down(md) {
								display: block;
							}
						}
						@include media-breakpoint-down(sm) {
							padding-left: 30px;
						}
					}
					.dropdown-item {
						font-size: 12px;
						font-style: normal;
						padding-left: 50px;
						@include media-breakpoint-up(md) {
							padding-left: 20px;
							max-width: 100%;
							width: 100%;
						}
					}
				}
			}

		}
		.navbar-phone {
			-webkit-box-flex: 3;
			-ms-flex: 3;
			flex: 3;
			padding-top: 24px;
			font-size: 17px;
			font-style: italic;
			font-weight: 700;
			text-align: center;
			@media (max-width: 1220px) {
				display: none;
			}
			@include media-breakpoint-down(md) {
				-webkit-box-ordinal-group: 4;
				-ms-flex-order: 3;
				order: 3;
				margin-bottom: 50px;
			}
			&__link {
				color: $main-font-color;
				font-family: $font-family;
				transition: color 0.1s linear;
				&:hover {
					color: $accent-color;
					text-decoration: none;
				}
			}
			&-hidden{
				display: none;
				.icon{
					display: none;
				}
				@include media-breakpoint-down(md) {
					display: block;
					margin-bottom: 0;
					padding-top: 0;
					flex: none;
					order: 0;
				}
				@include media-breakpoint-down(xs) {
					position: absolute;
					right: 55px;
					top: 15px;
					.icon{
						display: block;
						img{
							width: 25px;
							height: 25px;
						}
					}
					.number{
						display: none;
					}
				}
			}
		}

		.navbar-major-logo {
			-webkit-box-flex: 3;
			-ms-flex: 3;
			flex: 3;
			padding-top: 5px;
			margin-bottom: 10px;
			@media (min-width: $grid-breakpoints-lg) and (max-width: $grid-breakpoints-xl) {
				flex: none;
			}
			@include media-breakpoint-down(md) {
				display: block;
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
				text-align: center;
			}
			@include media-breakpoint-down(md) {
				display: block;
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
				text-align: center;
			}
			&__text {
				font-size: 11px;
				line-height: 8px;
				text-align: right;
				padding-right: 10px;
				font-weight: 400;
				font-family: $font-family-normal;
				letter-spacing: 0.5px;
				@include media-breakpoint-down(md) {
					text-align: center;
				}
			}
		}
		.navbar-contacts {
			order: 2;
			display: none;
			padding-bottom: 30px;
			@include media-breakpoint-down(md) {
				display: block;
			}

			.navbar-address {
				display: inline-block;
				@include media-breakpoint-down(sm) {
					padding-left: 30px;
				}
				@include media-breakpoint-down(xs) {
					padding-left: auto;
				}
			}

			.more-btn {
				margin-top: 15px;
				@include media-breakpoint-down(sm) {
					margin-left: 30px;
				}
				@include media-breakpoint-down(xs) {
					margin-left: auto;
				}
			}
		}
	}

	.dropdown-menu {
		border: none;
		margin-top: 0;
		border-radius: 0;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.11);

		@include media-breakpoint-down(lg) {
			width: 730px;
		}
		@include media-breakpoint-down(md) {
			max-height: 100%;
			box-shadow: none;
		}
		@include media-breakpoint-down(xs) {
			width: 1vh;
		}
		&__item-wrapper {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			@include media-breakpoint-down(lg) {
				width: max-content;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-box-pack: start;
				-ms-flex-pack: start;
				justify-content: flex-start;
			}
			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}
		.dropdown-item--car {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			width: 33.3333%;
			text-align: center;
			padding-top: 25px;
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&:hover {
				text-decoration: none;
				background-color: transparent !important;
				&>.car-text {
					color: $accent-color;
				}
			}
			.car-img {
				max-width: 138px;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
			.car-text {
				padding: 0;
				font-size: 12px;
				font-weight: 700;
				font-style: italic;
				color: $main-font-color;
				transition: color 0.1s linear;
				width: 100%;
				text-align: center !important;
				@include media-breakpoint-down(md) {
					text-align: left !important;
					padding-left: 30px;
					margin-bottom: 5px;
				}
				@include media-breakpoint-down(xs) {
					padding-left: 0;
				}
			}
			@include media-breakpoint-down(md) {
				padding-top: 12px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.dropdown:hover > .dropdown-menu {
			display: flex;
		}
		.dropdown > .dropdown-toggle:active {
			pointer-events: none;
		}
	}

	.dropdown-submenu {
		position: relative;
		.dropdown-item--sub {
			color: #A4A4A4;
			font-size: 12px;
			padding-left: 80px !important;
			@include media-breakpoint-up(md) {
				padding-left: 20px !important;
			}
		}
	}

	.dropdown-submenu a::after {
		transform: rotate(-90deg);
		position: absolute;
		right: 6px;
		top: .8em;
	}

	.dropdown-submenu .dropdown-menu {
		top: 0;
		left: 100%;
		margin-left: .1rem;
		margin-right: .1rem;
	}
	.super-header {
		position: relative;
		display: none;
		box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.11);
		z-index: 20;
		@include media-breakpoint-down(lg) {
			display: block;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
		&__wrapper {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
		}
		.navbar-phone {
			padding-top: 18px;
			font-size: 17px;
			font-style: italic;
			font-weight: 700;
			text-align: center;
			margin-right: 23px;
			&__link {
				color: $main-font-color;
				font-family: $font-family;
				transition: color 0.1s linear;
				&:hover {
					color: $accent-color;
					text-decoration: none;
				}
			}
		}

		.navbar-major-logo {
			padding-top: 0px;
			&__text {
				font-size: 11px;
				line-height: 8px;
				font-weight: 400;
				font-family: $font-family-normal;
				letter-spacing: 0.5px;
			}
		}
	}
}

.header .navbar .navbar-collapse__wrapper .navbar-nav .show .nav-link .nav-arrow {
	transform: rotate(226deg) !important;
	top: 15px !important;
}

#spec-offers-dropdown {
	@include media-breakpoint-up(md) {
		display: none;
	}
}
#service-dropdown {
	@include media-breakpoint-up(md) {
		display: none;
	}
}
#about-dropdown {
	@include media-breakpoint-up(md) {
		display: none;
	}
}
#repair-dropdown {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.-ie .dropdown-model .dropdown-menu{
	@include media-breakpoint-up(lg) {
		width: 1100px;
		&__item-wrapper{
			width: 100%!important;
		}
		.dropdown-item--car{
			width: 100%!important;
		}

	}
	@include media-breakpoint-down(lg) {
		width: 800px;
		.dropdown-item--car{
			width: 33.3333%!important;
			flex: auto;
		}
	}
}
