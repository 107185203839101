.faq_list {

	.question_box {
		margin-top: 51px;
		margin-bottom: 100px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 50px;
		}
	}

	.question_head {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.question_title {
		font-size: 22px;
		font-family: $font-family-normal;
		font-weight: 400;
		line-height: 26px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-right: 15px;
		border-bottom: 2px solid $accent-color;
		max-width: 210px;
	}

	.question_date_category_wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 200px;
		margin-top: 3px;
	}

	.question_date {
		font-size: 12px;
		font-family: $font-family-normal;
		line-height: 14px;
	}

	.question_category {
		font-size: 12px;
		font-family: $font-family-normal;
		line-height: 14px;
	}

	.question_body {
		font-size: 16px;
		font-family: $font-family-normal;
		line-height: 22px;
		margin-top: 37px;

		@include media-breakpoint-down(md) {
			font-size: 15px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
	}

	.question_quest {

	}

	.question_answer {
		margin-top: 50px;
		background-color: #fafafa;
		padding: 50px 55px 38px 55px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: -32px;
			left: 25px;
			width: 0;
			height: 0;
			border-left: 40px solid transparent;
			border-right: 40px solid transparent;
			border-bottom: 40px solid #fafafa;
		}


		@include media-breakpoint-down(sm) {
			padding: 30px 30px 38px 30px;
		}
	}

	.ask-question-text {
		font-size: 25px;
		line-height: 29px;
		font-family: $font-family-normal;
		font-weight: 400;
	}

	.tabs-block .tabs {
		@include media-breakpoint-down(sm) {
			flex-wrap: nowrap;
		}
	}
}