.model-colors {
	position: relative;
	z-index: 1;
	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	&__title {
		font-size: 35px;
		text-transform: uppercase;
		margin-bottom: 0;
		letter-spacing: 0.5px;
		@include media-breakpoint-down(xs) {
			font-size: 25px;
		}
	}

	&__title-model{
		@include media-breakpoint-down(xs) {
			display: block;
		}
	}

	&__list-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0;
		padding-left: 0;

		@include media-breakpoint-down(md) {
			justify-content: flex-start;
			margin-top: 14px;
		}

		@include media-breakpoint-down(xs) {
			flex-wrap: wrap;
			max-width: 210px;
		}
	}

	&__list--1 {
		.model-colors__item:last-child {
			margin-right: 16px;
			@include media-breakpoint-down(lg) {
				margin-right: 6px;
			}
		}
	}

	&__item {
		list-style: none;
		width: 60px;
		height: 17px;
		border: 1px solid transparent;
		transition: border 0.1s linear;
		cursor: pointer;
		&:not(:last-child) {
			margin-right: 16px;
			@include media-breakpoint-down(lg) {
				margin-right: 6px;
			}
		}
		&:hover {
			border: 1px solid #000;
		}

		@include media-breakpoint-down(lg) {
			width: 34px;
			min-width: 34px;
			height: 17px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 10px;
		}
	}

	&__item--1 {
		background-color: #FED22E;
	}
	&__item--2 {
		background-color: #0089BE;
	}
	&__item--3 {
		background-color: #F2F2F2;
	}
	&__item--4 {
		background-color: #A9A9A9;
	}
	&__item--5 {
		background-color: #454545;
		@include media-breakpoint-down(xs) {
			float: left;
			clear: both;
		}
	}
	&__item--6 {
		background-color: #6A141F;
	}
	&__item--7 {
		background-color: #6B6A6F;
	}
	&__item--8 {
		background-color: #FE5434;
	}
	&__item--9 {
		background-color: #DC846F;
	}
	&__item--10 {
		background-color: #8086A6;
	}
}

.car-in-stock-info {
	.model-colors {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
		&__list-wrapper {
			flex-direction: column;
			@include media-breakpoint-down(sm) {
				max-width: 100%;
			}
		}
		&__list {
			margin-bottom: 15px;
			@include media-breakpoint-down(xl) {
				justify-content: flex-start;
				flex-wrap: wrap;
				max-width: 100%;
			}
			.model-colors__item {
				width: 60px;
				min-width: 60px;
				margin-bottom: 10px;
			}
			.model-colors__item:last-child {
				margin-right: 0;
			}
		}
	}
}