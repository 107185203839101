.car-options {
	margin-top: 80px;
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}

	ul {
		li {
			height: 0;
			opacity: 0;
			&:nth-child(-n+10) {
				height: auto;
				opacity: 1;
			}
		}
	}

	.show-all, .hide-all {
		position: relative;
		z-index: 2;
		background: none;
		border: none;
		font-size: 16px;
		font-family: $font-family-normal;
		text-decoration: underline;
		margin-left: 20px;
		&:hover {
			text-decoration: none;

		}
	}

	.hide-all {
		display: none;
	}

	.additional-options {
		margin-top: 80px;
	}
}