.car-in-stock-showroom {
	margin-top: 40px;
	@include media-breakpoint-down(xs) {
		background-color: #F9F9F9;
	}
	&__content {
		display: flex;
		flex-direction: row;
	}

	&__img {
		height: 318px;
		width: 40%;
		background-image: url('/files/resources/images/crop_828x390_3 (1).png');
		background-size: cover;
		@include media-breakpoint-down(lg) {
			height: 244px;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__address-block {
		width: 60%;
		background-color: #F9F9F9;
		padding-left: 32px;
		padding-top: 40px;
		padding-bottom: 32px;
		@include media-breakpoint-down(lg) {
			padding-top: 20px;
			padding-bottom: 3px;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			padding-bottom: 20px;
		}
		@include media-breakpoint-down(xs) {
			padding-left: 0;
		}
	}

	&__name {
		font-size: 25px;
		font-family: $font-family-normal;
		font-weight: bold;
		text-transform: none;
		font-style: normal;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	&__text {
		margin-bottom: 35px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 10px;
		}
	}

	&__contact {
		margin-bottom: 10px;
	}
}

.contacts-page {
	.car-in-stock-showroom {
		margin-top: 30px;
		margin-bottom: 95px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 40px;
		}

		.container {
			background-color: #fff !important;
		}

		&__title {
			font-size: 35px;
			margin-bottom: 35px;
			@include media-breakpoint-down(xs) {
				top: 0px;
			}
		}

		&__content {
			display: flex;
			flex-direction: row;
			flex-basis: 50%;
			margin-right: -15px;
			margin-left: -15px;
			@include media-breakpoint-down(xs) {
				flex-direction: column-reverse;
				margin-right: 0;
				margin-left: 0;
			}
		}

		&__address-block {
			padding-top: 0;
			padding-left: 0;
			background-color: #fff;
			flex: 8;
			margin-right: 15px;
			margin-left: 15px;
			@include media-breakpoint-down(xs) {
				flex: unset;
				margin-right: 0;
				margin-left: 0;
				margin-top: 15px;
			}
		}

		&__img {
			height: 318px;
			width: 40%;
			background-image: url('/files/resources/images/contacts/Image-76@2x.png');
			background-size: cover;
			background-position: bottom;
			flex: 7;
			margin-right: 15px;
			margin-left: 15px;

			@include media-breakpoint-down(md) {
				display: block;
				height: 218px;
			}
			@include media-breakpoint-down(sm) {
				height: 180px;
			}
			@include media-breakpoint-down(xs) {
				flex: unset;
				margin-right: 0;
				margin-left: 0;
				height: 190px;
				width: 100%;
			}
		}

		&__name {
			@include media-breakpoint-down(md) {
				font-size: 19px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 15px;
			}
		}

		&__text {
			margin-bottom: 50px;
			@include media-breakpoint-down(md) {
				font-size: 14px;
				margin-bottom: 35px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11px;
				margin-bottom: 15px;
			}
		}

		&__contact {
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				font-size: 14px;
				margin-bottom: 8px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11px;
			}
		}

		&__contact--phone {
			a {
				color: $main-font-color;
				transition: color 0.1s linear;
				&:hover {
					text-decoration: none;
					color: $accent-color;
				}
			}
		}

		.more-btn {
			margin-top: 60px;
			max-width: 400px;
			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
			@include media-breakpoint-down(xs) {
				max-width: 100%;
			}
		}
	}
}

.car-in-stock-showroom__title--md {
	font-size: 28px;
	text-transform: uppercase;
	margin-top: 50px;
	@include media-breakpoint-down(md) {
		margin-bottom: 0;
		margin-top: 50px;
	}
}