.used-advantages {
	background-color: #F9F9F9;
	padding-top: 45px;
	padding-bottom: 45px;
	overflow-x: hidden;
	overflow-y: hidden;

	h2 {
		font-family: $font-family;
		font-size: 30px;
		text-transform: uppercase;
		font-weight: 700;
		font-style: italic;
		margin-bottom: 40px;
	}

	&__wrapper {
		position: relative;
		padding-left: 60px;
		padding-right: 60px;
	}

	.slider-advantages {
		opacity: 0;

	}

	.slick-initialized  {
		opacity: 1;
		transition: opacity 0.3s linear;
	}

	.slider-item-advantages {
		display: flex !important;
		flex-direction: row;
		justify-content: space-between;
		margin-right: 25px;
		margin-left: 25px;
		min-height: 150px;
		@include media-breakpoint-down(sm) {
			margin-right: 0;
			margin-left: 0;
		}
	}

	.number-wrapper {
		box-shadow: 9px 0 5px -5px lightgray;
		position: relative;
		overflow: hidden;
		width: 290px;
		max-height: 155px;
		z-index: 3;
		text-align: right;
		min-height: 150px;
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}
	
	.number {
		font-size: 145px;
		color: #F0AC01;
		font-weight: 700;
		position: absolute;
		right: -18px;
		line-height: 145px;
		display: block;
		width: 100%;
		@include media-breakpoint-down(sm) {
			right: -9px;
		}
	}

	.info {
		position: relative;
		z-index: 2;
		background-color: #F9F9F9;
		padding-left: 15px;
		padding-right: 30px;
		@include media-breakpoint-down(xs) {
			padding: 0;
		}
	}

	.title {
		font-family: $font-family-normal;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 15px;
		margin-top: 5px;
		font-size: 20px;
	}

	.text {
		font-size: 18px;
		line-height: 23px;
		color: $main-font-color;
		margin-bottom: 0;
	}

	.nav-list {
		.prev, .next {
			background-color: #F0AC01 !important;
			width: 21px;
			height: 46px;
			.nav-arrow {
				border-color: #fff;
				top: 19px;
			}
		}

		.prev {
			left: -60px;
			.nav-arrow {
				right: -5px !important;
			}
		}

		.next {
			right: 60px;
			.nav-arrow {
				right: -4px !important;
			}
		}
	}

	.slick-track {
		display: flex !important;
		animation: appear 1s linear;
	}

	.slick-slide {
		height: inherit !important;
	}

	.slick-disabled {
		opacity: 0;
		pointer-events:none;
		&:hover {
			opacity: 0;
		}
	}
}
