.img-wrapper {
	height: 536px;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	@include media-breakpoint-down(md) {
		height: 365px;
	}
	@include media-breakpoint-down(sm) {
		height: 295px;
	}
	@include media-breakpoint-down(xs) {
		height: 263px;
	}
	@media (max-width: 460px) {
		height: 240px;
	}
	@media (max-width: 420px) {
		height: 200px;
	}
}