.estimation-form-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.disclaimer--required {
		margin-top: 0px;
	}

	.ik_select_dropdown {
		left: 0px !important;
		top: 34px !important;
		width: 100% !important;
	}
}

#estimation_place {
	max-width: 720px;
	
	.ik_select_dropdown {
		left: 0px !important;
		top: 34px !important;
		width: 100% !important;
	}

	.form_group_wrapper:nth-child(4) {

		.wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-left: -15px;
			margin-right: -15px;

			.hidden_field_wrapper {
				display: none;
			}
			.name-wrapper {
				max-width: 46%;
				margin-right: 15px;
				margin-left: 15px;
				width: 100%;
				@include media-breakpoint-down(md) {
					max-width: 100%;
				}
				&:nth-child(11) {
					width: 100%;
					max-width: 100%;
					.label_wrapper label {
						@include media-breakpoint-down(sm) {
							max-width: 500px !important;
						}
						@include media-breakpoint-down(xs) {
							max-width: 418px !important;
						}
					}

					.formjor-asterisk {
						display: none;
					}

					.ftype_checkbox {
						.form-control {
							top: -24px !important;
							@include media-breakpoint-down(xs) {
								top: -41px !important;
							}
						}
					}
				}
			}
		}
	}

	.label_wrapper label {
		top: 0px !important;
		margin-bottom: 4px !important;
		@include media-breakpoint-down(sm) {
			top: 10px;
		}
	}

	.button-wrapper {
		width: 100%;
		margin-right: 15px;
		margin-left: 15px;
	}

	.answer_block {
		.answer_block_button {
			max-width: 720px;
			width: 100%;
		}
	}
}