.credit-form-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
	max-width: 840px;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	h3 {

		@include media-breakpoint-down(sm) {
			margin-top: 20px;
			font-size: 20px;
		}
	}

	.credit_form_car_info {
		img {
			max-width: 100%;
		}
	}

	.car_info {
		padding-top: 30px;
		@include media-breakpoint-down(sm) {
			padding-top: 15px;
		}
	}

	.car_name {
		font-size: 22px;
		line-height: 26px;
		font-family: $font-family-normal;
		@include media-breakpoint-down(sm) {
			font-size: 18px;
		}
	}

	.car_specs {
		font-size: 16px;
		line-height: 18px;
		font-family: $font-family-normal;
		margin-top: 6px;
		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}
	}

	.car_info_credit_price {
		font-size: 40px;
		line-height: 45px;
		font-family: $font-family-normal;
		font-weight: 700;
		margin-top: 20px;
		@include media-breakpoint-down(sm) {
			font-size: 30px;
			margin-top: 10px;
		}
	}

	.form-control {
		font-family: $font-family-normal;
	}
	.noUi-origin .noUi-background .noUi-stacking {
		background: #e8e8e8;
		border: none;
		border-radius: 0;
	}

	.noUi-target {
		height: 6px;
		border: 1px solid #000;
	}
	.noUi-base {
		height: 4px;
		background-color: $accent-color;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}

	.noUi-connect {
		background: $accent-color !important;
		box-shadow: none !important;
	}

	.noUi-horizontal .noUi-handle {
		position: absolute;
		top: -9px;
		height: 20px;
		width: 20px;
		border: 1px solid #000;
		background-color: $accent-color;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		box-shadow: none;
		&:before, &:after {
			display: none;
		}
	}

	.noUi-marker-horizontal.noUi-marker {
		display: none;
		left: unset;
	}

	.noUi-value {
		&:nth-child(2) {
			left: 14px !important;
		}
	}

	.noUi-value {
		&:nth-child(10) {
			left: 97% !important;
		}
	}

	#slider_years {
		padding-left: 15px;
	}

	#slider_price {
		padding-left: 15px;
	}

	.noUi-pips-horizontal {
		top: -45px;
		padding: 10px 15px;
	}

	.noUi-value-horizontal {
		padding-top: 0;
		font-size: 16px;
		font-family: $font-family-normal;
		color: #000;

	}

	.slider_content {
		position: relative;
		top: 25px;
	}

	#calc_credit_button {
		position: relative;
		margin-top: 70px;
		max-width: 100%;
		cursor: pointer;
		.button-text {
			left: 0;
		}
	}

	.hidden {
		display: none;
	}

	.form_result_list {
		font-family: $font-family-normal;
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			margin-top: 10px;
		}

		.result_block_col {
			margin-bottom: 7px;
		}

		.title {
			padding-left: 0;

			@include media-breakpoint-down(sm) {
				.title, .value {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	.form-control {
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: lightgrey;
			opacity: 1; /* Firefox */
			text-align: left;
			font-family: $font-family-normal;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: lightgrey;
			text-align: left;
			font-family: $font-family-normal;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: lightgrey;
			text-align: left;
			font-family: $font-family-normal;
		}
	}

	.disclaimer {
		padding-left: 0;
	}

	#calc_results {
		.fields_group {
			padding-top: 50px;
		}
	}

	.credit_calculation_block {
		.field_wrapper:nth-child(2) {
			@include media-breakpoint-down(xs) {
				margin-top: 50px;
			}
		}
	}

	.result_block_col {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.value {
			font-weight: 700;
		}
	}

	.answer_block {
		width: 800px;
		@include media-breakpoint-down(sm) {
			width: 500px !important;
		}

		@include media-breakpoint-down(xs) {
			width: 320px !important;
		}
	}

	#slider_price {
		@include media-breakpoint-down(xs) {
			.noUi-value-horizontal:last-child {
				left: 96% !important;
			}
			.noUi-value-horizontal:nth-child(4) {
				left: 34.5% !important;
			}
			.noUi-value-horizontal:nth-child(6) {
				left: 68% !important;
			}
		}
	}
}