.text-img {
	padding-top: 80px;
	.wrapper-text-img {
		@include media-breakpoint-down(lg) {
			display: block;
		}
		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
		}
	}
	.article-text {
		padding-left: 20px;
		padding-right: 15px;
		@include media-breakpoint-down(xs) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.wrapperimg {
		img {
			display: block;
			max-width: 100%;
			float: right;
			margin-left: 20px;
		}
		@include media-breakpoint-down(xl) {
			img {
				width: 471px;
			}
		}
		@include media-breakpoint-down(lg) {
			img {
				width: 369px;
			}
		}
		@include media-breakpoint-down(md) {
			img {
				width: 313px;
			}
		}
		@include media-breakpoint-down(xs) {
			img {
				display: block;
				max-width: 100%;
				float: none;
				width: 100%;
				margin-left: 0;
			}
		}
	}
}


