.used-links {
	margin-bottom: 30px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 0px;
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		@include media-breakpoint-down(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	.more-btn {
		background-color: #fff;
		color: $main-font-color;
		width: 100%;
		max-width: 333px;
		margin-left: 0;
		margin-right: 40px;
		margin-top: 15px;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			&:last-child {
				margin-right: 0;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-right: 15px;
			.button-text {
				left: -9px;
			}
		}

		&:hover {
			background-color: $accent-color;
			color: #fff;
			.nav-arrow {
				border-color: #fff;
			}
		}
		
		.nav-arrow {
			border-color: $main-font-color;
		}
	}

	.model-links__wrapper {
		@include media-breakpoint-up(lg) {
			max-width: unset;
		}
	}

	.model-links__title {
		@include media-breakpoint-down(md) {
			font-size: 18px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 20px;
		}
	}

	.model-links__list {
		.model-links__item:first-child {
			max-width: unset;
		}

		.model-links__item:nth-child(2) {
			max-width: unset;
		}
	}
}

.car-in-stock-links.used-links {
	@include media-breakpoint-up(lg) {
		top: 0;
	}
	@include media-breakpoint-up(sm) {
		max-width: unset;
	}
}