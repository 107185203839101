.used-info {
	.more-btn--car {
		@include media-breakpoint-down(lg) {
			position: absolute;
			bottom: 80px;
			left: -546px;
			width: 530px;
			max-width: 530px;
		}
		@include media-breakpoint-down(md) {
			position: absolute;
			bottom: 225px;
			left: -409px;
			width: 395px;
			max-width: 395px;
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
			bottom: 0 !important;
			max-width: 100%;
			width: 100%;

			.nav-arrow {
				display: none;
			}

			.button-text {
				left: 0;
			}
		}

		padding-left: 15px;
		padding-right: 15px;
	}

	.fotorama__arr--prev, .fotorama__arr--next {
		background-color: $accent-color;
	}

	.fotorama__arr--prev {
		left: 0;
	}

	.fotorama__arr--next {
		right: 0;
	}

	.button-up {

		@include media-breakpoint-down(lg) {
			bottom: 132px !important;
		}
		@include media-breakpoint-down(md) {
			bottom: 246px !important;
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
			bottom: 0 !important;
			max-width: 100%;
			width: 100%;

		}
	}
}