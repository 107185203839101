.individual-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
	max-width: 840px;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.form_inner_wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 840px;
		width: 100%;
	}

	.car_image_wrapper {
		text-align: center;
		margin-right: 15px;
		flex: 1;
		img {
			max-width: 100%;
		}
	}

	.individual_form_info {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	.name-price-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding-top: 30px;
	}

	.label_wrapper {
		.check_name {
			padding-left: 20px;
		}
	}

	.field_wrapper {
		width: 100%;
		flex: 100%;
		max-width: 100%;
		.label_wrapper {
			.i_agree_personal_data-label {
				@include media-breakpoint-down(sm) {
					padding-left: 15px !important;
				}

				a {
					color: $accent-color;
					&:hover {
						color: $main-font-color;
					}
				}
			}
		}
	}

	.field_wrapper:nth-child(9) {
		width: 47%;
		flex: 47%;
		max-width: 47%;
		@include media-breakpoint-down(sm) {
			width: 100%;
			flex: 100%;
			max-width: 100%;
		}
	}
	.field_wrapper:nth-child(8) {
		width: 47%;
		flex: 47%;
		max-width: 47%;
		@include media-breakpoint-down(sm) {
			width: 100%;
			flex: 100%;
			max-width: 100%;
		}
	}

	.button-wrapper {
		width: 100%;
	}

	.answer_block_button {
		flex: 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.car-name, .car-price {
		font-weight: 400;
	}

	.car-price {
		font-size: 30px;
		line-height: 45px;
		font-family: "Arial", sans-serif;
		font-weight: 700;
	}

	.car-name {
		font-size: 18px;
		line-height: 22px;
		font-family: "Arial", sans-serif;
	}

	.ftype_checkbox {
		.form-control {
			@include media-breakpoint-down(sm) {
				top: -40px;
			}
		}
	}

	.form-control {
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: lightgrey;
			opacity: 1; /* Firefox */
			text-align: left;
			font-family: $font-family-normal;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: lightgrey;
			text-align: left;
			font-family: $font-family-normal;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: lightgrey;
			text-align: left;
			font-family: $font-family-normal;
		}
	}

	.middle_margin_bottom {
		margin-top: 30px;
	}

	a {
		color: $main-font-color;
		&:hover {
			text-decoration: none;
			color: $accent-color;
		}
	}

	.disclaimer {
		a {
			color: $accent-color;
			&:hover {
				color: $main-font-color;
			}
		}
	}
}