body {
	font-size: 18px;
	font-family: $font-family;
	min-width: 320px;
	overscroll-behavior: none;
}

#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5);
	cursor: pointer;
	z-index: 20;
	height: 120%;
}
.overlay--active {
	display: block !important;
}

.scroll-disable {
	overflow: hidden;
	.header {
		top: 0 !important;
	}
}

.html-scroll-disable {
	overflow: hidden;
	position: relative;
	height: 100%;
}

h1 {
	font-family: $font-family;
	font-size: 40px;
	font-style: italic;
	font-weight: 700;
	color: $main-font-color;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
	text-transform: uppercase;
	@include media-breakpoint-down(xs) {
		font-size: 35px;
	}
}
h2 {
	font-family: $font-family;
	font-size: 35px;
	font-style: italic;
	font-weight: 700;
	color: $main-font-color;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
	@include media-breakpoint-down(xs) {
		font-size: 30px;
	}
}
h3 {
	font-family: $font-family;
	font-size: 30px;
	font-style: italic;
	font-weight: 700;
	color: $main-font-color;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
	@include media-breakpoint-down(xs) {
		font-size: 25px;
	}
}
h4 {
	font-family: $font-family;
	font-size: 20px;
	font-style: italic;
	font-weight: 700;
	color: $main-font-color;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
	line-height: 25px;
	@include media-breakpoint-down(md) {
		line-height: 20px;
	}
}
p {
	font-family: $font-family-normal;
	font-size: 18px;
	font-weight: 400;
	color: $main-font-color;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
}

p {
	a {
		color: $accent-color;
		text-decoration: underline;
		&:hover {
			color: $main-font-color;
			text-decoration: none;
		}
	}
}

a {
	color: $main-font-color;
	&:hover {
		color: $accent-color;
		text-decoration: none;
	}
}