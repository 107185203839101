.car-used-info-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
	max-width: 720px;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.form-car-info-used {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 20px;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
		.item {
			flex: 40%;
		}

		.image-wrapper {
			margin-right: 15px;
			@include media-breakpoint-down(sm) {
				margin-right: 0;
			}
		}

		.info-wrapper {
			font-weight: 700;
		}
	}

	.button-wrapper {
		width: 100%;
		margin-right: 15px;
		margin-left: 15px;
	}

	.car-image {
		max-width: 100%;
		width: 100%;
	}

	.inputs-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
		.field_wrapper {
			&:nth-child(3) {
				flex: 40%;
				margin-right: 15px;
				@include media-breakpoint-down(sm) {
					margin-right: 0;
				}
			}
			&:nth-child(4) {
				flex: 40%;
			}
			&:nth-child(5) {
				flex: 40%;
			}
			&:nth-child(6) {
				@include media-breakpoint-down(sm) {
					margin-top: 20px;
					.i_agree_personal_data-label {
						padding-left: 15px !important;
					}
					.ftype_checkbox {
						.form-control {
							top: -40px;
						}
					}
				}
			}
		}
	}
}