.breadcrumbs-block {
	padding-top: 30px;
	@include media-breakpoint-down(md) {
		padding-top: 90px;
	}
}

.breadcrumbs {
	color: #A4A4A4;
	a {
		color: #A4A4A4;
	}
	.list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding-left: 0;
	}
	.item {
		color: #A4A4A4;
		list-style: none;
		margin-right: 40px;
		font-size: 15px;
		position: relative;
		&:last-child {
			color: $accent-color;
			pointer-events: none;
		}
		@include media-breakpoint-down(sm) {
			display: none;
			&:nth-last-child(2) {
				display: block;
				padding-left: 20px;
				.arrow {
					left: 2px;
					transform: rotate(132deg);
				}
			}
		}
		.link {
			&:hover {
				text-decoration: none;
				color: $accent-color;
			}
			&:hover {
				text-decoration: none;
				color: $accent-color;
			}
		}

		.arrow {
			position: absolute;
			top: 8px;
			right: -32px;
			display: inline-block;
			border-right: 2px solid #A4A4A4;
			border-bottom: 2px solid #A4A4A4;
			width: 7px;
			height: 7px;
			transform: rotate(-46deg);
			margin-right: 10px;
		}
		&:last-child {
			.arrow {
				display: none;
			}
		}
	}
	.lone-item {
		padding-left: 17px;
		.arrow {
			display: block;
			left: 6px;
			transform: rotate(135deg);

		}
	}
}

.breadcrumbs-wrapper {
	.breadcrumbs-block {
		padding-top: 30px;
		@include media-breakpoint-down(md) {
			padding-top: 90px;
		}
	}
}