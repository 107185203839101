.used-specs {
	.container {
		padding-left: 0;
		padding-right: 0;
	}

	.block-table {
		margin-top: 0;
		margin-bottom: 30px;
	}

	.table {
		display: table;
	}

	.table-row {
		font-size: 15px;
		font-family: $font-family-normal;
		border-bottom-color: #DECDCD;
		padding-top: 0px;
		padding-bottom: 0px;
		&:hover {
			background-color: unset;
			border: none;
			box-shadow: 0 0 0 0;
		}
		&:first-child {
			.table-cell {
				border-top: none;
				&:last-child {
					font-weight: 700;
				}
			}
		}
		&:nth-child(2) {
			.table-cell {
				border-top: none;
				&:last-child {
					font-weight: 700;
				}
			}
		}
		&:last-child {
			.table-cell {
				border-bottom: none;
			}
		}
	}

	.table-cell  {
		border-left: none;
		border-right: none;
		font-size: 15px;
		padding-left: 0;
		width: 50%;
		padding-top: 8px;
		padding-bottom: 8px;
	}
}