.standard-gallery {
	margin-top: 40px;
	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
	.wrapper {
		position: relative !important;
	}
}

.slider-standard {

	@include media-breakpoint-down(md) {
		height: 365px !important;
	}
	@include media-breakpoint-down(sm) {
		height: 295px !important;
	}
	@include media-breakpoint-down(xs) {
		height: 263px !important;
	}
	.slick-list {
		height: 500px;
		@include media-breakpoint-down(md) {
			height: 365px!important;
		}
		@include media-breakpoint-down(sm) {
			height: 295px !important;
		}
		@include media-breakpoint-down(xs) {
			height: 263px !important;
		}

		.slick-slide {
			height: 500px;
			@include media-breakpoint-down(md) {
				height: 365px;
			}
			@include media-breakpoint-down(sm) {
				height: 295px;
			}
			@include media-breakpoint-down(xs) {
				height: 263px;
			}
			.slider-item-standard {
				height: 500px;
				.content-standard {
					min-height: 100%;
					-webkit-background-size: cover;
					background-size: cover;
					background-position: center;
				}
				@include media-breakpoint-down(md) {
					height: 365px;
					background-position: center -50px;
				}
				@include media-breakpoint-down(sm) {
					height: 295px;
				}
				@include media-breakpoint-down(xs) {
					height: 263px;
				}
			}

		}
	}
}

.slick-dots {
	position: relative !important;
	top: -50px;
	text-align: center;
	li {
		list-style: none;
		opacity: 0.5;
		height: 15px;
		width: 15px;
		background-color: #fff !important;
		border-radius: 50%;
		margin: 0 10px;
		button {
			outline: none;
			&:before {
				display: none;
			}
		}
	}
	.slick-active {
		opacity: 0.8;
	}
}

.nav-list {
	margin-top: -45px;
	position: absolute;
	top: 50%;
	width: 100%;
	@include media-breakpoint-down(md) {
		margin-top: -25px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: -25px;
	}

	li {
		list-style: none;
		position: absolute;
		color: #cdcbcd;
		width: 30px;
		height: 80px;
		background-color: #fff !important;
		opacity: 0.8;
		font-size: 30px !important;
		line-height: 60px !important;
		text-align: center;
		&:hover {
			opacity: 0.5;
		}
		.nav-arrow {
			position: absolute;
			top: 36px;
			display: inline-block;
			border-right: 3px solid #000;
			border-bottom: 3px solid #000;
			width: 10px;
			height: 10px;
			margin-right: 10px;
		}
	}
	.prev {
		left: 0px;
		cursor: pointer;
		.nav-arrow {
			transform: rotate(130deg);
			right: 0px;
		}
	}
	.next {
		right: 0px;
		cursor: pointer;
		.nav-arrow {
			transform: rotate(-46deg);
			right: 0px;
		}
	}
}