p {
	font-family: $font-family;
}

.cars-in-stock {
	font-family: $font-family !important;
	&__title {
		font-size: 35px;
		margin-top: 30px;
		text-transform: uppercase;
		@include media-breakpoint-down(xs) {
			font-size: 30px;
		}
	}

	.cars__list {
		margin-right: -15px;
		margin-left: -15px;
		transition: opacity 0.4s linear 0.5s;
	}

	.text-block {
		margin-top: 88px;
		h2 {
			font-size: 35px;
			@include media-breakpoint-down(xs) {
				font-size: 25px;
			}
		}
		p {
			font-size: 17px;
		}
	}
}

.car-item-in-stock {
	list-style: none;
	margin-bottom: 20px;
	position: relative;
	padding-right: 0;
	padding-left: 0;
	&__wrapper {
		padding: 0 15px;
		transition: opacity 0.1s linear;
		display: block;
		color: $main-font-color;
		&:hover {
			text-decoration: none;
			color: $main-font-color;
		}
	}

	&__link {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 2;
	}

	&__img {
		width: 224px;
		transform: scale(-1, 1);
	}

	&__img-main {
		width: 224px;
		transform: scale(-1, 1);
	}

	&__model-item-volume {
		margin-left: 10px;
	}

	&__title {
		font-size: 15px;
		font-style: normal;
		font-family: $font-family-normal;
		margin-bottom: 10px;
		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 15px;
		}
	}

	&__spec {
		margin-bottom: 10px;
	}

	&__spec-item {
		padding-top: 0;
		font-size: 15px;
		margin-bottom: 0;
		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 11px;
		}
	}

	&__colors {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100px;
	}

	&__colors-list {
		padding-left: 5px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	&__colors-text {
		font-size: 15px;
		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
	}

	&__colors-item {
		height: 27px;
		width: 27px;
		background-color: lightgreen;
		list-style: none;
		border: 1px solid lightgrey;
		z-index: 2;
		text-align: center;
		font-size: 16px;
		margin-right: 4px;
	}

	&__colors-item-link {
		display: block;
		width: 100%;
		height: 100%;
	}

	&__price-block {
		margin-bottom: 15px;
	}

	&__price-block-sm {
		display: none;
	}

	&__price-block-group {
		margin-bottom: 15px;
	}

	&__price {
		font-family: $font-family-normal;
		font-size: 28px;
		font-weight: 400;
		margin-bottom: 0;
		line-height: 30px;
		@include media-breakpoint-down(lg) {
			font-size: 27px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 23px;
		}
	}

	&__price-new {
		font-weight: 700;
	}

	&__min-price {
		margin-top: 15px;
		padding-left: 10px;
		background-color: #f1f0ec;
		background-image: url("/files/resources/images/min-price-arrows.png");
		background-repeat: no-repeat;
		width: 261px;
		height: 56px;
		padding-top: 4px;
	}

	&__min-price-sm {
		margin-top: 15px;
		padding-left: 13px;
		background-color: #f1f0ec;
		background-image: url("/files/resources/images/min-price-arrows.png");
		background-repeat: no-repeat;
		width: 100%;
		height: 52px;
		padding-top: 2px;
		background-position: 100% 0%;
	}

	&__min-price-text {
		font-size: 15px;
		font-family: $font-family;
	}

	&__min-price-text-sm {
		font-size: 14px;
		font-family: $font-family;
	}

	&__min-price-digits {
		font-size: 20px;
		margin-top: -3px;
		margin-bottom: 0;
	}

	&__min-price-digits-sm {
		font-size: 18px;
		margin-top: -3px;
		margin-bottom: 0;
	}

	.more-btn {
		margin-left: 0;
		width: 100%;
		margin-left: 0;
		max-width: initial;
		cursor: pointer;
		z-index: 1;
	}

	&__about-container {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__about {
		padding-left: 50px;
	}

	&__about-title {
		font-size: 25px;
		font-weight: bold;
		line-height: 1.2;
	}

	&__about-subtitle {
		line-height: 2.3;
	}

	&__model-container {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
		height: 95px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px 0 30px;
		font-size: 15px;
		border: 3px solid transparent;
		margin-bottom: 10px;
	}

	&__img-modification {
		cursor: pointer;
	}

	&__model-item-inet_name {
		width: 158px;
		font-size: 25px;
		margin-left: -41px;
		font-weight: bold;
		line-height: 1.3;
	}

	&__model-item-price {
		font-size: 25px;
		font-weight: bold;
		width: 170px;
		padding-left: 21px;
	}

	&__modifications {
		display: flex;
		flex-direction: column;
		border-left: 3px solid #f5c626;
		border-right: 3px solid #f5c626;
		border-bottom: 3px solid #f5c626;
		margin-bottom: 13px;
	}

	&__modifications-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-right: 17px;
		padding-left: 25px;
		font-size: 15px;
		border-bottom: 3px solid #f8f8f8;
		opacity: 0.9;
		height: 209px;
	}

	&__modifications-item:hover {
		color: #000000;
		opacity: 1;
	}

	&__modifications-price {
		font-size: 25px;
		font-weight: bold;
		padding-left: 21px;
	}

	&__modifications-price-md {
		font-size: 23px;
		font-weight: bold;
		line-height: 1;
		text-align: right;
		display: none;
	}

	&__modifications-price-sm {
		font-size: 25px;
		font-weight: bold;
		display: none;
		margin: 13px 0 16px 13px;
		line-height: 1.2;
	}

	&__modifications-bottom-block {
		display: none;
		margin-top: -29px;
	}

	&__model-item-engine-sp {
		display: none;
	}

	&__model-item-gear-sp {
		display: none;
	}

	&__model-item-price-sp {
		width: 261px;
		text-align: right;
		display: none;
		font-size: 23px;
		font-weight: bold;
	}

	&__model-subitem-voleng {
		font-size: 15px;
		font-weight: normal;
		display: none;
	}

	&__model-subitem-hpgear {
		font-size: 15px;
		font-weight: normal;
		display: none;
	}

	&__model-item-tech-md {
		display: none;
	}

	&__model-item-call-container {
		position: relative;
		width: 261px;
		height: 34px;
		border: 1px solid #f5c626;
		padding-top: 4px;
		text-align: center;
	}

	&__model-item-call-container-sm {
		position: relative;
		width: 100%;
		height: 34px;
		border: 1px solid #f5c626;
		padding-top: 4px;
		text-align: center;
		display: none;
		margin-top: 15px;
	}

	&__model-item-call-container:hover {
		background: #f0ac01;
		color: #ffffff;
	}

	&__model-item-call-container-text {
		font-size: 13px;
		font-weight: bold;
	}

	&__modifications-vhpengearyear-sm {
		margin-left: 13px;
		display: none;
	}
}

.tabs-block {
	.tabs-wrapper {
		padding-bottom: 10px;
	}

	.tabs {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		max-width: 100%;
	}

	ul.tabs {
		margin-bottom: 0;
		padding: 0px;
		list-style: none;
	}
	ul.tabs .tabs-item {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		font-family: $font-family-normal;
		margin-right: 15px;
		border-bottom: 3px solid $accent-color;
		font-weight: 400;
		font-size: 14px;
		text-align: center;
		margin-bottom: 15px;
		min-width: 145px;
		min-height: 40px;
		&:hover {
			a {
				color: #000000 !important;
			}
			background-color: #f8f8f8;
		}
		&.active {
			a {
				color: #000000 !important;
				font-weight: bold;
			}
			background-color: #f8f8f8;
		}
		@include media-breakpoint-down(sm) {
			flex: none;
		}

		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: #585858;
			text-decoration: none;
			padding: 3px 12px;
			width: 100%;
			min-height: 100%;
			text-align: center;
			&:hover {
				text-decoration: none;
				color: $main-font-color;
			}
		}
	}

	ul.tabs {
		a {
			color: $main-font-color;
		}
	}

	ul.tabs li.current {
		background: #ededed;
		color: #222;
	}

	.tab-content {
		display: block;
		background: #ededed;
		padding: 15px;
	}

	.tab-content.current {
		display: inherit;
	}

	.tab_container {
		margin-top: 0;
	}
}

.car-out-of-stock {
	margin-top: 30px;
	&__img {
		.wrapper {
			.next {
				right: 30px;
			}
		}
	}

	&__price {
		font-size: 34px;
		font-family: $font-family-normal;
		line-height: 34px;
		text-align: center;
		font-weight: 700;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			text-align: left;
		}
	}

	&__buttons-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
		@include media-breakpoint-down(md) {
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.more-btn {
			margin-top: 15px;
			@include media-breakpoint-down(md) {
				max-width: 49%;
				width: 49%;
			}
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	&__text-interest {
		font-size: 16px;
		font-weight: 700;
		line-height: 17px;
		margin-bottom: 8px;
		font-family: $font-family-normal;
		text-align: center;
		@include media-breakpoint-down(md) {
			text-align: left;
		}
	}

	&__text-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 0px;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 1.3;
		margin-bottom: 20px;
		font-family: $font-family-normal;
		max-width: 250px;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			width: 100%;
		}
	}

	&__phone-wrapper {
		text-align: center;
		@include media-breakpoint-down(sm) {
			text-align: left;
		}
	}

	&__phone {
		font-size: 21px;
		font-weight: 700;
		color: $main-font-color;
		&:hover {
			color: $accent-color;
			text-decoration: none;
		}
	}

	&__ask-availability {
		font-size: 30px;
		font-family: $font-family-normal;
		margin-bottom: 0px;
		@include media-breakpoint-down(sm) {
			font-size: 25px;
			margin-top: 30px;
		}
	}

	&__phone--availability {
		font-size: 35px;
		@include media-breakpoint-down(sm) {
			font-size: 28px;
		}
	}

	&__links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__buttons-wrapper--model {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		max-width: 655px;
		margin-top: 35px;

		.more-btn {
			max-width: 300px;
			@include media-breakpoint-down(md) {
				max-width: 49%;
				width: 49%;
			}
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				width: 100%;
			}
			&:first-child {
				@include media-breakpoint-down(md) {
					max-width: 49%;
					width: 49%;
				}
				@include media-breakpoint-down(sm) {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}

	&__buttons-wrapper--in-stock {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		margin-top: 35px;
		margin-bottom: 70px;

		.more-btn {
			margin-right: 15px;
			margin-left: 15px;
			max-width: 300px;
			@include media-breakpoint-down(md) {
				max-width: 49%;
				width: 49%;
			}
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
			&:first-child {
				@include media-breakpoint-down(md) {
					max-width: 49%;
					width: 49%;
				}
				@include media-breakpoint-down(sm) {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
}

.car-in-stock-estimate-links {
	margin-bottom: 30px;
	@include media-breakpoint-down(lg) {
		position: relative;
		top: -39px;
	}
	@include media-breakpoint-down(md) {
		top: 0px;
	}
	.used-links {
		margin-top: 0;

		.more-btn {
			max-width: 333px;
			@include media-breakpoint-down(sm) {
				max-width: 100%;
			}
		}
	}
}

@media (max-width: 1290px) {
	.car-item-in-stock {
		&__model-item-engine {
			display: none;
		}
		&__model-item-gear {
			display: none;
		}
		&__model-item-engine-sp {
			display: block;
		}
		&__model-item-gear-sp {
			display: block;
		}
		&__modifications-vhpengearyear {
			margin-left: 20px;
		}
	}
}

@media (max-width: 1289px) and (min-width: 1023px) {
	.car-item-in-stock {
		&__colors-text {
			display: none;
		}
	}
}


@media (max-width: 1024px) {
	.car-item-in-stock {
		&__min-price {
			width: 185px;
		}
		&__model-item-volume {
			margin-left: -38px;
		}
		&__model-item-call-container {
			width: 185px;
		}
	}
}

@media (max-width: 992px) {
	.car-item-in-stock {
		&__min-price {
			width: 261px;
		}
		&__model-item-volume {
			margin-left: 10px;
		}
		&__model-item-call-container {
			width: 261px;
		}
		&__colors {
			padding: 0 10px 0 10px;
			min-width: 110px;
		}
		&__img {
			width: 94px;
		}
		&__model-item-volume {
			margin-left: -24px;
		}
		&__model-container {
			padding: 0 30px 0 17px;
		}
		&__modifications-vhpengearyear {
			margin-left: 0;
		}
		&__modifications-item {
			height: 155px;
		}
	}
}

@media (max-width: 991px) and (min-width: 862px) {
	.car-item-in-stock {
		&__model-item-hp {
			opacity: 0;
		}
		&__model-subitem-hpgear {
			display: block;
		}
		&__model-subitem-voleng {
			display: block;
		}
		&__model-item-volume {
			display: none;
		}
		&__model-container {
			height: 113px;
		}
	}
}

@media (max-width: 861px) and (min-width: 769px) {
	.car-item-in-stock {
		&__model-item-volume {
			display: none;
		}
		&__model-item-hp {
			display: none;
		}
		&__model-subitem-voleng {
			display: block;
		}
		&__model-subitem-hpgear {
			display: block;
		}
		&__model-container {
			height: 120px;
		}
		&__modifications-item {
			flex-wrap: wrap;
		}
		&__modifications-vhpengearyear {
			display: none;
		}
		&__modifications-bottom-block {
			display: block;
		}
	}
}

@media (max-width: 768px) {
	.car-item-in-stock {
		&__model-item-hp {
			display: none;
		}
		&__model-item-volume {
			display: none;
		}
		&__model-container {
			padding: 0 16px 0 11px;
			height: 144px;
		}
		&__modifications-price {
			font-size: 20px;
			display: none;
		}
		&__model-item-price {
			display: none;
		}
		&__model-item-price-sp {
			display: block;
		}
		&__about-title {
			width: 345px;
		}
		&__model-item-tech-md {
			display: block;
		}
		&__modifications-price-md {
			display: block;
		}
		&__model-item-call-container {
			margin-top: 10px;
		}
	}
}

@media (max-width: 709px) and (min-width: 575px) {
	.car-item-in-stock {
		&__model-item-tech-md {
			display: none;
		}
		&__model-subitem-voleng {
			display: block;
		}
		&__model-subitem-hpgear {
			display: block;
		}
		&__modifications-item {
			flex-wrap: wrap;
		}
		&__modifications-vhpengearyear {
			display: none;
		}
		&__modifications-bottom-block {
			display: block;
		}
	}
}

@media (max-width: 576px) {
	.car-item-in-stock {
		&__modifications-bottom-block {
			display: none;
		}
		&__model-item-inet_name {
			width: 155px;
		}
		&__model-container {
			padding: 0 20px 0 16px;
		}
		&__model-item-price-sp {
			width: 164px;
		}
		&__min-price {
			width: 215px;
			height: 46px;
			padding-top: 0;
			background-size: auto 100%;
		}
		&__min-price-text {
			font-size: 11px;
		}
		&__min-price-digits {
			font-size: 15px;
		}
		&__img-main {
			margin-left: -80px;
		}
		&__modifications-item {
			display: block;
			width: 47%;
			margin: 7px;
			padding: 0;
			height: 445px;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
			border-bottom: none;
		}
		&__modifications {
			flex-direction: row;
			flex-wrap: wrap;
		}
		&__img {
			width: 100%;
		}
		&__model-item-tech-md {
			display: none;
		}
		&__model-subitem-voleng {
			display: block;
		}
		&__model-subitem-hpgear {
			display: block;
		}
		&__about {
			padding-left: 26px;
		}
		&__model-item-price-sp {
			width: 214px;
		}
		&__modifications-vhpengearyear {
			display: none;
		}
		&__modifications-vhpengearyear-sm {
			display: block;
		}
		&__modifications-price-md {
			display: none;
		}
		&__modifications-price-sm {
			display: block;
		}
		&__modifications-price-md {
			width: 237px;
		}
		&__colors-text {
			font-size: 15px;
		}
		&__colors {
			padding: 0 13px 0 13px;
		}
		&__price-block {
			display: none;
		}
		&__model-item-call-container {
			display: none;
		}
		&__price-block-sm {
			display: block;
		}
		&__model-item-call-container-sm {
			display: block;
		}
	}
}

@media (max-width: 537px) and (min-width: 454px) {
	.car-item-in-stock {
		&__model-item-inet_name {
			font-size: 18px;
		}
		&__model-item-inet_name {
			width: 100px;
		}
		&__model-container {
			padding: 0 7px 0 7px;
		}
		&__about {
			padding-left: 14px;
		}
		&__about-title {
			width: 248px;
		}
		&__model-item-price-sp {
			width: 143px;
		}
		&__min-price {
			width: 143px;
		}
		&__model-item-hp {
			display: block;
			opacity: 0;
		}
	}
}

@media (max-width: 502px) and (min-width: 437px) {
	.car-item-in-stock {
		&__modifications-item {
			width: 46%;
			height: 392px;
		}
	}
}

@media (max-width: 453px) and (min-width: 374px) {
	.car-item-in-stock {
		&__model-item-inet_name {
			font-size: 18px;
		}
		&__model-item-inet_name {
			width: 100px;
		}
		&__model-container {
			padding: 0 7px 0 7px;
		}
		&__img-main {
			margin-left: -135px;
		}
		&__about {
			padding-left: 14px;
		}
		&__about-title {
			width: 248px;
		}
		&__model-item-price-sp {
			width: 143px;
		}
		&__min-price {
			width: 143px;
		}
	}
}

@media (max-width: 436px) and (min-width: 384px) {
	.car-item-in-stock {
		&__modifications-item {
			width: 46%;
			height: 392px;
		}
		&__modifications-price-sm {
			font-size: 20px;
		}
	}
}

@media (max-width: 385px) and (min-width: 375px) {
	.car-item-in-stock {
		&__modifications-item {
			width: 45%;
			height: 364px;
		}
		&__modifications-price-sm {
			font-size: 20px;
		}
	}
}

@media (max-width: 375px) {
	.tabs-block .tabs {
		flex-wrap: nowrap;
		overflow-x: scroll;
	}
	.tabs-block ul.tabs .tabs-item {
		min-width: 100px;
	}
	.tabs-block ul.tabs .tabs-item a {
		width: 116px;
		line-height: 1.3;
		color: #000000;
	}
	.car-item-in-stock {
		&__model-container {
			height: 95px;
		}
		&__model-item-inet_name {
			font-size: 14px;
		}
		&__model-subitem-hpgear {
			font-size: 12px;
		}
		&__model-subitem-voleng {
			font-size: 12px;
		}
		&__model-item-inet_name {
			width: 90px;
		}
		&__model-item-price-sp {
			width: 154px;
		}
		&__min-price {
			width: 164px;
			height: 35px;
			margin-top: 1px;
			line-height: 1.3;
		}
		&__img-main {
			margin-left: -73px;
		}
		&__about-title {
			font-size: 20px;
			width: 144px;
		}
		&__about-subtitle {
			font-size: 19px;
			line-height: 1.1;
			margin-top: 16px;
		}
		&__about {
			padding-left: 13px;
		}
		&__model-container {
			padding: 0 10px 0 10px;
		}
		&__wrapper {
			padding: 0;
		}
		&__model-item-price-sp {
			font-size: 15px;
		}
		&__min-price-text {
			font-size: 9px;
		}
		&__min-price-digits {
			font-size: 12px;
		}
		&__modifications-price-sm {
			font-size: 19px;
		}
		&__img {
			width: 96%;
		}
		&__modifications-item {
			width: 46%;
			height: 325px;
		}
		&__modifications-vhpengearyear-sm {
			font-size: 12px;
		}
		&__colors-text {
			font-size: 12px;
		}
		&__modifications-price-sm {
			line-height: 1.4;
		}
		&__min-price-text-sm {
			font-size: 9px;
			line-height: 2;
		}
		&__min-price-digits-sm {
			font-size: 12px;
		}
		&__min-price-sm {
			height: 35px;
			line-height: 1;
		}
		&__min-price-sm {
			background-size: auto 100%;
		}
		&__colors-item {
			width: 22px;
			height: 22px;
			font-size: 13px;
		}
	}
}

@media (max-width: 356px) {
	.car-item-in-stock {
		&__modifications-item {
			width: 45%;
		}
		&__min-price {
			width: 120px;
		}
		&__model-item-price-sp {
			width: 120px;
		}
	}
}

.mod-border {
	border: 3px solid #f5c626;
}

.nav-arrow-more {
	display: inline-block;
	border-right: 2px solid #000000;
	border-bottom: 2px solid #000000;
	width: 9px;
	height: 9px;
	transform: rotate(-46deg);
}

.nav-arrow-more--1 {
	right: 93px;
}
