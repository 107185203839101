.footer {
	padding-top: 75px;
	padding-bottom: 20px;
	font-family: $font-family-normal;
	@include media-breakpoint-down(xl) {
		padding-top: 40px;
	}
	@include media-breakpoint-down(lg) {
		padding-top: 20px;
		overflow-x: hidden;
	}
	&__wrapper {
		@include media-breakpoint-down(sm) {
			padding-left: 0;
		}
	}

	&__findnewroads {
		position: absolute;
		width: 200px;
		height: 30px;
		bottom: 45px;
		right: 0;
		@media (max-width: 329px) {
			bottom: 75px;
		}

		@include media-breakpoint-down(md) {
			top: -37px;
		}
	}

	&__socials-security {
		margin-top: 40px;
		position: relative;

		.disclaimer {
			font-size: 10px !important;
		}
	}

	&__security {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
	}

	&__security-link {
		font-family: $font-family;
		font-size: 14px;
		color: $main-font-color;
		transition: color 0.1s linear;
		&:hover {
			color: $accent-color;
			text-decoration: none;
		}
	}

	hr {
		color: $main-font-color;
	}

	.socials {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		padding-left: 0;
		@include media-breakpoint-down(xs) {
			margin-bottom: 60px;
		}
		&__item {
			list-style: none;
			margin-right: 7px;
		}
	}

	.navbar-collapse-wrapper {
		width: 100%;
	}

	.navbar-nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 100%;
		background: none !important;
		padding: 0 !important;
		border: none;
		@include media-breakpoint-down(md) {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
		}
	}

	.navbar {
		background: none !important;
		padding: 0 !important;
	}

	.dropdown-item {
		border: none;
		padding: 1px 0px;
	}


	.nav-link {
		font-family: $font-family;
		padding: 0 !important;
		font-size: 12px;
		color: $main-font-color !important;
		transition: color 0.1s linear;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		position: relative;
		display: inline-block;
		&:hover {
			color: $accent-color !important;
			text-decoration: none;
			.nav-arrow {
				border-color: $accent-color;
			}
		}
		&:active {
			color: $accent-color;
			text-decoration: none;
			.nav-arrow {
				border-color: $accent-color;
			}
		}
		&:focus {
			color: $accent-color;
			text-decoration: none;
		}
		&:after {
			display: none;
		}
		@include media-breakpoint-up(xl) {
			font-size: 17px;
		}
		@include media-breakpoint-down(lg) {
			margin-top: 25px;
		}

	}

	.nav-arrow {
		position: absolute;
		top: 2px;
		right: -20px;
		border-right: 2px solid black;
		border-bottom: 2px solid black;
		width: 9px;
		height: 9px;
		transform: rotate(43deg);
		display: none;
		&--open {
			transform: rotate(224deg);
			top: 6px;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.dropdown-menu {
		border: none;
		position: initial !important;
		font-size: 14px;
		padding-top: 0;
		display: none;
	}


	.dropdown-menu--footer {
		display: none;
		@include media-breakpoint-up(lg) {
			display: block !important;
		}
	}

	.dropdown-item {
		color: $main-font-color !important;
		transition: color 0.1s linear;
		font-size: 13px;
		padding-right: 10px;
		&:hover {
			background: none !important;
			color: $accent-color !important;
		}
		@include media-breakpoint-up(xl) {
			font-size: 14px;
		}
	}

	.show {
		display: block;
	}
}

.footer .show .nav-link .nav-arrow {
	transform: rotate(226deg);
	top: 5px;
}