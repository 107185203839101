.faq-form-container {
	padding: 0 !important;
	max-width: 630px !important;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		width: 100% !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.ik_select_dropdown {
		left: 0px !important;
		top: 34px !important;
		width: 100% !important;
	}

	.form_inner_wrapper {
		width: 100% !important;
		max-width: 100% !important;
		flex: 100% !important;
	}

	.input-form-wrapper {
		width: 100% !important;
		max-width: 100% !important;
		flex: 100% !important;
		.item-1, .item-2, .item-3, .item-4, item-5 {
			padding-left: 0 !important;

		}
	}

	.field_wrapper {
		.label_wrapper {
			margin-top: 0px;
			height: 20px;
			flex: 100%;
			max-width: 100%;
			margin-bottom: 7px;
		}

		&:nth-child(7) {
			.label_wrapper {
				margin-top: 30px;
				@include media-breakpoint-down(sm) {
					margin-top: 40px;
				}
			}

			.error_wrapper {
				margin-top: 20px;
			}
		}
	}

	.ik_select_link_focus {
		box-shadow: none;
	}

	.ik_select {
		top: 0px !important;
	}

	.disclaimer, .ftype_checkbox {
		visibility: visible !important;
		display: block;
	}

	.ftype_checkbox .form-control {
		top: -23px;
	}

	.field_wrapper .label_wrapper {
		visibility: visible !important;
	}

	.i_agree_personal_data-label {
		padding-left: 15px !important;
	}

	.ik_select_link_novalue {
		.ik_select_link_text {
			opacity: 0;
			visibility: hidden;
		}
	}

	.i_agree_personal_data-label {
		display: block;
		padding-left: 25px;
		@include media-breakpoint-down(xs) {
			font-size: 13px;
		}
	}

	.disclaimer {
		font-size: 15px;
		margin-bottom: 0;
	}

	.disclaimer--required {
		margin-top: 40px;
	}

	.row {
		width: 100%;
		margin-right: 0;
		margin-left: 0;
		.col-xs-12 {
			width: 100% !important;
			max-width: 100%;
		}
	}
}