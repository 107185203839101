.standard-notes {
  margin-top: 40px;
  h2 {
    font-size: 28px;
    text-transform: uppercase;
  }
}

#additional {
  .disclaimer p {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: #7f7f7f;
  }
}