.links {
	margin-top: 80px;
	&__wrapper {
		max-width: 500px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
		}
	}
}
.route-link {
	color: $main-font-color;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 16px;
	margin-bottom: 10px;
	width: fit-content;
	&:hover {
		text-decoration: underline;
		color: $accent-color;
	}
}

.link-with-icon {
	color: $main-font-color;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	.a {
		fill: #000;
	}
	.link-icon {
		display: inline-block;
		margin-right: 5px;
	}
	&:hover {
		color: $accent-color;
		.a {
			fill: $accent-color;
		}
	}
	span {
		padding-bottom: 6px;
	}
}