.min-header {
	box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.11);
	position: relative;
	z-index: 19;
	font-family: $font-family-normal;
	min-height: 60px;
	@include media-breakpoint-down(md) {
		display: none;
	}

	&__container {
		position: relative;
		z-index: 19;
	}
	.navbar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 100%;
		padding: 0;
		background-color: #fff !important;
		&-collapse__wrapper{
			width: 100%;
		}
	}
	.navbar-nav {
		display: block;
		@include media-breakpoint-down(md) {
			padding-left: 0;
		}
		.nav-item {
			display: inline-block;
			.nav-link {
				font-size: 11px;
				font-family: $font-family-normal;
				color: $main-font-color;
				font-weight: 700;
				transition: color 0.1s linear;
				padding-top: 22px;
				padding-bottom: 20px;
				&:hover {
					color: $accent-color;
					.nav-arrow {
						border-right: 2px solid;
						border-bottom: 2px solid;
					}
				}
				&:after {
					display: none;
				}

				.nav-arrow {
					position: absolute;
					top: 26px;
					right: -5px;
					display: inline-block;
					border-right: 2px solid black;
					border-bottom: 2px solid black;
					width: 7px;
					height: 7px;
					transform: rotate(43deg);
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
			}

			.active {
				color: $accent-color;
			}

			.dropdown-menu {
				transform: skew(-27deg);
				border-radius: 0px;
				left: -90px;
				border: none;
				box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.11), 0px 10px 14px -2px rgba(0, 0, 0, 0.11);
				.dropdown-item {
					transform: skew(27deg);
					font-family: $font-family-normal;
					font-weight: 700;
					color: #7A7A7A;
					font-size: 12px;
					&:hover {
						background-color: transparent;
						color: $accent-color;
					}
				}
			}
			.show {
				display: -webkit-box !important;
				display: -ms-flexbox !important;
				display: flex !important;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}
		}
	}
}

.min-header .navbar .navbar-collapse__wrapper .navbar-nav .show .nav-link .nav-arrow {
	transform: rotate(226deg) !important;
	top: 28px !important;
}