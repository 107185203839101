.cars {
	&__title {
		font-family: $font-family;
		font-size: 28px;
		color: $main-font-color;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 70px;
		@include media-breakpoint-down(sm) {
			text-align: left;
			padding-left: 0;
			font-size: 25px;
		}
		@include media-breakpoint-down(xs) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 0px;
		}
	}
	&__list {
		padding-left: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			margin-right: 0px;
			margin-left: 0px;
		}
	}
}


.-hide {
	visibility: hidden;
}

.car-item {
	list-style: none;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 50px;
	margin-bottom: 30px;

	&:hover {
		.car-item__offers {
			color: #000;
		}
	}

	@include media-breakpoint-down(xs) {
		text-align: center;
		margin-bottom: 10px;
	}

	&__wrapper {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-down(xs) {
			max-width: 280px;
		}
	}

	&__img {
		width: auto;
		align-self: center;
		width: 100%;
	}

	&__title {
		font-family: $font-family-normal;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 5px;
		font-style: normal;
		max-width: 300px;
		@include media-breakpoint-down(xs) {
			text-align: left;
		}
	}

	&__price {
		font-family: $font-family-normal;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 0px;
		@include media-breakpoint-down(xs) {
			text-align: left;
		}
	}

	&__offers {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 15px;
		font-size: 18px;
		font-family: $font-family-normal;
		height: 37px;
		background-color: #F8F8F8;
		border-left: 3px solid $accent-color;
		@include media-breakpoint-down(xs) {
			width: 100%;
			margin-left: 0;
			max-width: initial;
		}
	}

	.more-btn {
		position: absolute;
		bottom: 0px;
		max-width: 285px;
		@include media-breakpoint-down(lg) {
			max-width: 289px;
		}
		@include media-breakpoint-down(md) {
			max-width: 329px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 224px;
		}

		@include media-breakpoint-down(xs) {
			max-width: 280px;
			position: relative;
		}
	}
}

.more-btn {
	font-family: $font-family-normal;
	display: block;
	text-align: center;
	position: relative;
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	line-height: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 30px;
	width: 100%;
	max-width: 245px;
	border: 1px solid $accent-color;
	background-color: $accent-color;
	text-transform: uppercase;
	text-decoration: none;
	@include media-breakpoint-down(xs) {
		width: 100%;
		margin-left: 0;
		max-width: initial;
	}
	&:hover {
		text-decoration: none;
		color: $main-font-color;
		background-color: #fff;
		.button-text {
			.nav-arrow {
				border-color: #000;
				transition: border-color 0.1s linear;
			}
		}
	}
	.button-text {
		position: relative;
		left: -15px;
	}
	.nav-arrow {
		position: absolute;
		top: 4px;
		right: -32px;
		display: inline-block;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		width: 9px;
		height: 9px;
		transform: rotate(-46deg);
		margin-right: 10px;
		&--1 {
			right: -23px;
		}
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
}

.models-list {
	.cars__title {
		font-size: 35px;
		font-weight: 700;
		font-style: italic;
		text-transform: none;
	}
}

.used-info {
	.more-btn {
		max-width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}