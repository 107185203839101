.not-found {
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		font-size: 22px;
		font-weight: 700;
		line-height: 25px;
		text-align: center;
		margin-top: 0px;
		font-family: $font-family-normal;
		font-style: normal;
	}

	&__digits {
		font-size: 84px;
		line-height: 94px;
		color: $accent-color;
		font-weight: 700;
		font-family: $font-family-normal;
		margin-top: 100px;
		@include media-breakpoint-down(sm) {
			margin-top: 60px;
		}

		&--center {
			color: $main-font-color;
		}
	}

	&__text {
		font-family: $font-family-normal;
		font-size: 16px;
		line-height: 21px;
		font-weight: 400;
		max-width: 510px;
		margin-top: 10px;
		text-align: center;
	}

	&__menu-wrapper {
		min-width: 700px;
		margin-top: 75px;
	}

	.navbar-nav-404 {
		padding-left: 0;
		padding-top: 21px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		max-width: 700px;
		margin: 0 auto;
		margin-bottom: 120px;
		@include media-breakpoint-down(lg) {
			padding-top: 25px;
			padding-bottom: 20px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 505px;
			margin-bottom: 0px;
		}
		@include media-breakpoint-down(xs) {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			align-items: center;
		}

		.nav-item-404 {
			list-style: none;
			font-size: 12px;
			text-transform: uppercase;
			position: relative;
			@include media-breakpoint-down(md) {
				font-size: 13px;
				font-weight: 700;
			}
			@include media-breakpoint-down(xs) {
				margin-bottom: 10px;
			}


			&:not(:last-child) {
				&:before {
					position: absolute;
					content: '/';
					color: $accent-color;
					top: -4px;
					right: -74px;
					font-weight: bold;
					font-size: 22px;
					@include media-breakpoint-down(sm) {
						right: -20px;
					}
					@include media-breakpoint-down(xs) {
						display: none;
					}
				}
			}
		}

		.nav-link-404 {
			color: $main-font-color;
			transition: color 0.1s linear;
			position: relative;
			display: inline-block;
			font-size: 17px;
			font-weight: 400;

			&:after {
				display: none;
			}

			&:hover {
				color: $accent-color;
				text-decoration: none;
			}

			&:active {
				color: $accent-color;
				text-decoration: none;
			}

			&:focus {
				color: $accent-color;
				text-decoration: none;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 30px;
			}
		}
	}
}