/* libs */
@import "../../../vendor/blocking/scss/bootstrap/bootstrap";
@import "../../../vendor/blocking/scss/bootstrap/bootstrap-grid";
@import "../../../vendor/blocking/scss/bootstrap/bootstrap-reboot";
@import "../../../vendor/blocking/scss/bootstrap/nav";
@import "../../../vendor/blocking/scss/bootstrap/dropdown";
@import "../../../vendor/blocking/scss/bootstrap/navbar";
@import "../../../vendor/blocking/scss/bootstrap/mixins";
@import "../../../vendor/blocking/scss/bootstrap/variables";
@import "../../../vendor/blocking/scss/bootstrap/functions";

@import "fonts";
@import "variables";
@import "global";
@import "slick";
@import "block-header";
@import "block-min-header";
@import "block-promo";
@import "block-cars";
@import "block-news";
@import "block-advantages";
@import "block-actions";
@import "block-about";
@import "block-other-pages";
@import "block-contacts";
@import "block-footer";

// страница Стандарт

@import "block-text";
@import "block-wide-img";
@import "block-text-with-img";
@import "block-links";
@import "block-lists";
@import "block-table";
@import "block-accordion";
@import "block-text-with-link";
@import "block-standard-gallery";
@import "block-standard-disclaimer";
@import "block-standard-tabs";
@import "block-standard-notes";
@import "block-breadcrumbs";

// страница новостей
@import "block-news-list";
@import "block-paginator";
@import "block-single-news";

// страница модельного ряда
@import "block-cars-in-stock";
@import "block-model-slider";
@import "block-model-colors";
@import "block-model-specs";
@import "block-model-links";
@import "block-model-text";
@import "block-model-tabs";
@import "block-in-stock-min";

// страница авто в наличии

@import "block-car-in-stock-title";
@import "block-car-in-stock-info";
@import "block-car-in-stock-price";
@import "block-car-in-stock-showroom";
@import "block-car-in-stock-similar";
@import "block-car-in-stock-specs-table";
@import "block-car-options";

// авто с пробегом
@import "block-used-list";
@import "block-used-expert";
@import "block-used-specs";
@import "block-used-advantages";
@import "block-used-options";
@import "block-used-similar";
@import "block-used-links";
@import "block-used-info";

//контакты
@import "block-map";

//контентная страница
@import "block-content";

//служебные страницы
@import "block-404";
@import "block-old-browser";
@import "block-db-fail";
@import "block-soft-404";

//вопрос-ответ
@import "block-faq-search-panel";
@import "block-faq-main";
@import "block-faq-list";
@import "block-faq-categories";

//формы
@import "forms";
@import "faq-ask-form";
@import "test-drive-form";
@import "call-back-form";
@import "estimation-form";
@import "individual-form";
@import "feedback-form";
@import "credit-form";
@import "car-used-info-form";


@import "block-managers";

// страница модели
@import "block-model-page";
@import "block-model-page-camaro";
@import "block-model-page-taho";
@import "block-model-page-traverse";
@import "block-model-page-corvette";


// карта сайта
@import "block-sitemap";