.feedback-form-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 810px;
  max-width: 840px;
  @include media-breakpoint-down(sm) {
    width: auto;
    max-width: 500px !important;
    width: 100% !important;
    .fw_win_content_block {
      width: auto !important;
    }
  }

  p {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    max-width: 320px !important;
    .fw_win_content {
      padding-left: 15px;
      padding-right: 15px;
    }

    .fw_win_title {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .hidden_field_wrapper {
    display: none;
  }

  .ftype_checkbox .form-control {
    @include media-breakpoint-down(sm) {
      top: -42px;
    }
    @include media-breakpoint-down(xs) {
      top: -39px;
    }
  }

  .form_inner_wrapper {
    width: 100% !important;
    max-width: 100% !important;
    flex: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .field_wrapper {
      width: 47%;
      max-width: 47%;
        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 100%;
        }
      &:nth-child(6) {
        max-width: 100%;
        width: 100%;
      }
      &:nth-child(7) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .form_success_answer_wrapper {
    p {
      display: block;
    }
  }
}