.sitemap {
	.main-list {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
		.main-item {
			margin-bottom: 50px;
			font-family: $font-family-normal;
			list-style: none;
			&:before {
				display: none;
			}
		}

		.main-item-link {
			font-weight: 700;
		}

		ul {
			margin-top: 0;
		}

		.sitemap-third-level {
			margin-bottom: 20px !important;
			li {

			}

			a {
				font-size: 16px;
			}
		}
	}
}