.fw_win_content_block {
	display: inline-block;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.fw_win_close_button {
	height: 20px !important;
	width: 20px !important;
	background-image: url("/files/resources/images/close-button-white.png") !important;
	background-position: 0 0 !important;
	background-repeat: no-repeat !important;
	-webkit-background-size: 20px !important;
	background-size: 20px !important;
	opacity: 1 !important;
	transition: opacity 0.1s linear;
	top: 36px !important;
	right: 27px !important;

	&:hover {
		opacity: 0.7 !important;
	}

	&:before {
		display: none !important;
	}

	@include media-breakpoint-down(xs) {
		top: 26px !important;
		right: 16px !important;
	}
}

.fwin_body_scroll_fix {
	width: auto !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.fw_win_content {
	padding-left: 75px;
	padding-right: 75px;
	padding-bottom: 75px;
	padding-top: 40px;
	@include media-breakpoint-down(xs) {
		padding-top: 20px;
	}
}

.label_wrapper {
	padding-left: 12px;
	label {
		font-size: 15px !important;
		margin-bottom: 0 !important;
	}

	a {
		text-decoration: underline;
		color: $accent-color;
		transition: color 0.1s linear;
		&:hover {
			color: $main-font-color;
		}
	}
}

.fw_win_content_container {
	border-radius: 0px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.fw_win_title {
	width: 100%;
	color: #fff;
	margin-top: 0 !important;
	background-color: $accent-color;
	padding: 28px 75px 22px 75px;
	text-align: left !important;
	font-size: 25px !important;
	line-height: 32px;
	margin-left: 0 !important;
	height: auto !important;
}

//.formjor-asterisk {
//	display: none;
//}

.label_wrapper {
	padding-left: 12px;
	label {
		font-size: 15px !important;
		font-family: $font-family-normal !important;
		position: relative !important;
		@include media-breakpoint-down(sm) {
			max-width: 460px !important;
		}
		@include media-breakpoint-down(xs) {
			max-width: 320px !important;
		}
	}
}

.ik_select_option_label {
	font-family: $font-family-normal !important;
	font-size: 15px !important;
	color: #000 !important;
	padding-top: 7px !important;
	padding-bottom: 8px !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;

	&:after {
		display: none !important;
	}
}

.ik_select_dropdown .ik_select_list li.ik_select_hover {
	background: #E4E4E4 !important;
	border: none !important;
	box-shadow: none !important;
}

.ik_select_list {
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
	border-radius: 0px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.ik_select_dropdown .ik_select_list li {
	padding: 4px 11px !important;
}

.form-control.ik_select_link_novalue {
	background-color: #fff !important;

}

.ik_select_link {
	height: 33px !important;
	color: #000;
	position: relative;
	background: none !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	&:before {
		content: '';
		position: absolute;
		top: 11px;
		right: 15px;
		display: block;
		border-right: 2px solid black;
		border-bottom: 2px solid black;
		width: 7px;
		height: 7px;
		transform: rotate(43deg);
	}
}

.ik_select_link.active {
	&:before {
		top: 14px;
		transform: rotate(-134deg);
	}
}

.ik_select_link_novalue {
	.ik_select_link_text {
		color: lightgrey;
		@include media-breakpoint-down(sm) {
			width: 90%;
			text-align: center;
		}
	}
}

.ik_select_link_text {
	top: 6px;
	font-family: $font-family-normal !important;
}

.ik_select_dropdown .ik_select_list {
	box-shadow: none;
	border: 1px solid lightgrey;
}

.input_wrapper .form-control {
	display: block;
	padding-top: 7px !important;
	font-family: $font-family-normal;
	//height: 34px !important;
}

.input_wrapper {
	input {
		display: block !important;
	}
	.form-control {
		font-family: $font-family-normal !important;
		font-size: 15px !important;
		border-radius: 0px !important;
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			font-family: $font-family !important;
			font-size: 15px !important;
			opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			font-family: $font-family !important;
			font-size: 15px !important;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			font-family: $font-family !important;
			font-size: 15px !important;
		}
	}
}

.input-form-wrapper {
	padding-left: 0 !important;
	padding-right: 0 !important;

	&:not(:nth-child(7)) {
		margin-bottom: 20px;
	}

	&:nth-child(4){
		.label_wrapper {
			visibility: visible;
		}
	}
	.ftype_textarea textarea {
		min-height: 68px !important;
		display: block;
	}
}

.ik_select_link_focus {
	box-shadow: none !important;
}

.form-control {
	display: block;
	border: 1px solid #707070 !important;
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: transparent;
		opacity: 1; /* Firefox */
		padding-top: 8px !important;
		margin-top: 20px;
		font-size: 15px;
		font-family: $font-family-normal !important;
		display: none;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: transparent;
		padding-top: 8px !important;
		font-size: 15px;
		font-family: $font-family-normal !important;
		display: none;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: transparent;
		padding-top: 8px;
		font-size: 15px !important;
		font-family: $font-family-normal !important;
		display: none;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.ftype_checkbox {
	height: 0;
}

.ftype_checkbox .form-control {
	position: relative;
	top: -20px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	border: 1px solid $accent-color !important;
	width: 17px !important;
	height: 17px !important;
	padding: 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50% !important;
	padding-top: 5px !important;
	min-height: auto !important;
	@include media-breakpoint-down(sm) {
		top: -40px;
	}
	&:before {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		width: 11px;
		height: 11px;
		background-color: white;
		border-radius: 50%;
		vertical-align: middle;
		border: 1px solid white;
		outline: none;
		cursor: pointer;
	}
}

.form-control:checked {
	&:before {
		background-color: $accent-color !important;
		border-radius: 50%;
		border: 1px solid $accent-color !important;
	}
}

.form_submit_button {
	margin-top: 30px;
	margin-bottom: 30px;
	padding-top: 9px;
	padding-bottom: 9px;
	font-family: $font-family;
	font-weight: 700;
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
	font-style: normal;
	width: 100% !important;
	border: 1px solid $accent-color;
	background-color: $accent-color;
	transition: color 0.1s linear;

	&:hover {
		background-color: #fff;
		.text {
			color: #000;
			&:after {
				border-color: #000;
			}
			&:before {
				border-color: #000;
			}
		}
	}
	.text {
		position: relative;
		color: #fff;
		transition: color 0.1s linear;
		&:before {
			content: '';
			position: absolute;
			top: 5px;
			right: -22px;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
			width: 9px;
			height: 9px;
			margin-right: 10px;
			transform: rotate(-44deg);
		}
		&:after {
			content: '';
			position: absolute;
			top: 5px;
			right: -32px;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
			width: 9px;
			height: 9px;
			margin-right: 10px;
			transform: rotate(-44deg);
		}
	}
}

.form_success_answer_wrapper {
	ul {
		li {
			&:hover {
				a {
					color: $accent-color;
					text-decoration: none;
				}
			}
			a {
				transition: color 0.1s linear;
				color: $main-font-color;
			}
		}
	}
}

.close_window_button {
	font-family: $font-family-normal;
	display: block;
	text-align: center;
	position: relative;
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	line-height: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 30px;
	width: 100%;
	max-width: 100%;
	border: 1px solid $accent-color;
	background-color: $accent-color;
	text-transform: uppercase;
	text-decoration: none;
	transition: color 0.1s linear, background-color 0.1s linear;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: $main-font-color;
		background-color: #fff;
	}
}

.disclaimer {
	font-size: 13px !important;
	margin-bottom: 0;
	margin-top: 0;
	max-width: fit-content;
	padding-left: 0px;
	font-family: $font-family-normal;


	@include media-breakpoint-down(sm) {
		max-width: 100%;
		width: 100%;
	}

	&--required {
		margin-top: 20px;
	}

	a {
		color: $accent-color;
	}
}

.fw_win_title {
	text-transform: uppercase;
	font-family: $font-family;
	font-weight: 700;
	font-style: italic;
	@include media-breakpoint-down(xs) {
		font-size: 18px !important;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-right: 40px !important;
	}
}

.policy_form {
	max-width: 800px;
	@include media-breakpoint-down(sm) {
		max-width: 400px;
		width: 100%;
	}
	@include media-breakpoint-down(xs) {
		max-width: 320px;
		width: 100%;
	}

	.fw_win_title {
		background-color: #fff;
		color: #000;
		padding-left: 45px;
		@include media-breakpoint-down(sm) {
			padding-left: 0px;
			line-height: 25px !important;
		}
	}

	.fw_win_content {
		padding: 20px 45px 30px 45px;
	}

	.fw_win_close_button {
		background-image: url("/files/resources/images/close-button.png") !important;
		top: 27px !important;
	}
}



.form-agree-container {
	max-width: 840px;
	@include media-breakpoint-down(sm) {
		max-width: 400px;
		width: 100%;
		.fw_win_title {
			padding: 15px 0 0 0;
			color: #000 !important;
			line-height: 25px !important;
		}


	}
	@include media-breakpoint-down(xs) {
		max-width: 320px;
		width: 100%;
	}
	.fw_win_title {
		background-color: #fff;
		color: #000;

	}

	.fw_win_content {
		padding: 20px 45px 30px 45px;
	}

	.fw_win_close_button {
		background-image: url("/files/resources/images/close-button.png") !important;
		top: 27px !important;
	}
}

.form_success_answer_wrapper {
	padding-top: 15px;

	.success_header {
		font-size: 25px;
		text-transform: uppercase;
	}

	p {
		font-family: $font-family-normal;
		font-size: 18px;
	}
}

.safe_win {
	transition: color 0.1s linear;
	text-decoration: underline;
	&:hover {
		color: $main-font-color;
	}
}

.i_agree_personal_data-label {
	display: block !important;
	padding-left: 15px !important;
	@include media-breakpoint-down(xs) {
		font-size: 13px !important;
	}
}

.form-agree {
	background-color: #fff;
	.fw_win_title {
		color: $main-font-color;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 0px !important;
		padding-right: 0px !important;
		font-size: 14px !important;
		h1 {
			font-size: 20px;
			line-height: 25px;
		}
		p {
			font-size: 14px;
			line-height: 17px;
		}
	}
}

.formjor-err {
	.error_text {
		display: block;
	}
}

.error_text {
	font-size: 13px !important;
	font-family: $font-family-normal;
	color: red !important;
	display: none;
	position: absolute;
}

.faq-main {
	.input-wrapper {
		.form-control {
			display: block;
			padding-top: 7px !important;
			color: lightgrey !important;
		}
	}
}

.answer_block_button {
	a {
		background-color: $accent-color;
		padding: 12px 30px;
		color: #fff !important;
		margin-top: 20px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 15px;
		cursor: pointer;
		border: 1px solid $accent-color;

		&:hover {
			text-decoration: none;
			color: $main-font-color !important;
			background-color: #fff;
			.button-text {
				.nav-arrow {
					border-color: #000;
					transition: border-color 0.1s linear;
				}
			}
		}
	}
}