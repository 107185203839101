.used-options {
	margin-top: 70px;
	&__title {
		font-size: 30px;
		font-weight: 700;
		font-style: italic;
		text-transform: uppercase;
		margin-bottom: 52px;
	}

	.list-unorder {
		margin-bottom: 30px;

		li {
			padding-left: 0 !important;
		}
	}

	.list-title {
		font-family: $font-family-normal;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		margin-bottom: 20px;
		color: $main-font-color;
	}

	ul {
		padding-left: 20px;
	}

	li {
		list-style: none;
		margin-bottom: 15px;
		-ms-word-wrap: break-word;
		word-wrap: break-word;
		@include media-breakpoint-down(sm) {
			flex: 50% !important;
			max-width: 50% !important;
		}
	}

	.list-mark {
		display: block;
		left: -5px;
	}

	h2 {
		font-size: 28px;
		text-transform: uppercase;
	}
}