.used-expert {
	margin-top: 100px;
	&__wrapper {
		background-color: #F1F1F1;
		text-decoration: none;
		margin-right: 0;
		margin-left: 0;
		&:hover {
			text-decoration: none;
		}
	}

	&__img-wrapper {
		padding-left: 0;
		padding-right: 0;
		position: relative;
		min-height: 169px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include media-breakpoint-down(xs) {
			max-height: 156px;
		}
	}

	&__img {
		width: 100%;
		max-width: 417px;
		position: absolute;
		left: 57px;
		top: -77px;
		@include media-breakpoint-down(lg) {
			max-width: 289px;
			left: 20px;
			top: -20px;
		}
		@include media-breakpoint-down(md) {
			max-width: 417px;
			left: 147px;
			top: -78px;
		}
		@include media-breakpoint-down(sm) {
			left: 76px;
		}
		@include media-breakpoint-down(xs) {
			max-width: 328px;
			width: 100%;
			position: relative;
			top: 39px;
			left: unset;
			align-self: center;
		}
	}

	&__logo {
		background-color: #EC7616;
		height: 63px;
		text-align: center;
	}

	&__logo-img {
		width: 170px;
		@include media-breakpoint-down(lg) {
			width: 180px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 40px;
		@include media-breakpoint-down(lg) {
			padding-left: 20px;
		}
		@include media-breakpoint-down(md) {
			align-items: center;
			padding: 30px 0 40px 0;
		}
		@include media-breakpoint-down(sm) {
			align-items: center;
			padding-top: 37px;
		}
		@include media-breakpoint-down(xs) {
			align-items: flex-start;
			padding-right: 6px;
			padding-left: 20px;
		}
	}

	&__title {
		font-family: $font-family-normal;
		font-size: 38px;
		letter-spacing: 0.5px;
		color: #212529;
		text-align: center;
		font-weight: normal;
		margin-bottom: 0;
		font-style: normal;
		@include media-breakpoint-down(sm) {
			font-size: 33px;
		}
		@include media-breakpoint-down(xs) {
			text-align: left;
			margin-bottom: 7px;
		}
	}

	&__text-item {
		font-size: 22px;
		color: #212529;
		text-align: center;
		margin-bottom: 0;
		@include media-breakpoint-down(sm) {
			font-size: 19px;
		}
		@include media-breakpoint-down(xs) {
			text-align: left;
			line-height: 23px;
		}
	}
}