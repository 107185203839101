.advantages {
	font-family: $font-family;
	&__title {
		font-size: 28px;
		color: $main-font-color;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 45px;
		padding-left: 0px;
		margin-bottom: 50px;
		@include media-breakpoint-down(sm) {
			text-align: left;
			padding-left: 0;
			font-size: 25px;
			margin-top: 30px;
		}
		@include media-breakpoint-down(xs) {
			text-align: center;
		}
	}
	&__list {
		padding-left: 0;
		padding-right: 0px;
		padding-left: 0px;
		@include media-breakpoint-down(sm) {
			padding-left: 10px;
		}
	}
	&__item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		margin-bottom: 10px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}

		&:hover {
			.advantages__item-title {
				color: $accent-color;
			}
		}
	}
	&__item-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	&__item-title {
		font-size: 25px;
		color: $main-font-color;
		text-decoration: none;
		font-style: italic;
		font-weight: 700;
		transition: color 0.1s linear;
		text-transform: uppercase;
		margin-bottom: 5px;
		&:hover {
			color: $accent-color;
			text-decoration: none;
		}
		@include media-breakpoint-down(md) {
			padding-left: 0px;
			font-size: 17px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0px;
			font-size: 23px;
		}
	}
	&__declamation {
		font-size: 17px;
		line-height: 17px;
		color: $main-font-color;
		font-family: $font-family-normal;
		@include media-breakpoint-down(md) {
			padding-left: 0px;
			font-size: 12px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0px;
			font-size: 16px;
		}
	}
	&__img-block {
		position: relative;
	}
	&__img {
		position: relative;
		margin-right: 25px;
		@include media-breakpoint-down(lg) {
			margin-right: 15px;
		}
		@include media-breakpoint-down(md) {
			transform: scale(0.65);
			margin-right: -10px;
			top: -8px;
			left: -13px;
		}
		@include media-breakpoint-down(sm) {
			margin-right: 15px;
			transform: scale(1);
			top: 0px;
			left: 0px;
		}

		&--car {
			position: absolute;
			top: 21px;
			left: 16px;
			@include media-breakpoint-down(md) {
				transform: scale(0.65);
				top: 14px;
				left: 5px;
			}
			@include media-breakpoint-down(sm) {
				transform: scale(1);
				top: 21px;
				left: 16px;
			}
		}
	}
}