.other-pages{
	margin-top: 120px;
	margin-bottom: -40px;
	box-shadow: none;
	.navbar-nav .nav-item{
		margin-right: 20px;
		.nav-link{
			padding: 15px 0;
		}
	}

	.list-unorder {
		max-width: 75%;
		padding-left: 17px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			width: 100%;
		}
	}

	li {
		width: 50%;
		margin-bottom: 10px;
		@include media-breakpoint-down(lg) {
			width: 47%;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		a {
			color: #000;
			transition: color 0.1s linear;
			&:hover {
				color: $accent-color;
				text-decoration: none;
			}
		}
	}

	@include media-breakpoint-up(xs) {
		display: flex;
	}
}