.news {
	font-family: $font-family-normal;
	padding-bottom: 50px;
	overflow-x: hidden;
	.container {
		position: relative;

	}
	&__title {
		font-family: $font-family;
		font-size: 28px;
		color: $main-font-color;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 50px;
		padding-left: 0px;
		margin-bottom: 30px;
		@include media-breakpoint-down(sm) {
			text-align: left;
			padding-left: 0;
			font-size: 25px;
			margin-top: 0;
		}
		@include media-breakpoint-down(xs) {
			text-align: center;
		}
	}
	&__list {
		padding-left: 0;
		padding-left: 0px;
		@include media-breakpoint-down(sm) {
			padding-right: 0;
			padding-left: 0;
		}
	}
	&__see-all-link {
		font-family: $font-family;
		font-size: 20px;
		font-weight: 700;
		font-style: italic;
		color: $main-font-color;
		text-decoration: underline;
		text-transform: uppercase;
		transition: color 0.1s linear;
		position: absolute;
		right: 28px;
		bottom: -20px;
		&:hover {
			color: $accent-color;
		}
	}
}

.news-item {
	list-style: none;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 30px;
	position: relative;
	@include media-breakpoint-down(lg) {
		&:last-child {
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		&:last-child {
			display: none;
		}
		&:nth-child(3) {
			display: none;
		}
	}
	@include media-breakpoint-down(xs) {
		padding-left: 20px;
		padding-right: 20px;
		margin-top: 40px;
		&:nth-child(3) {
			display: none;
		}
	}

	&__wrapper {
		display: block;
		margin: 0 auto;
		position: relative;
		padding-bottom: 35px;
		height: 100%;
		&:hover {
			text-decoration: none;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
		@include media-breakpoint-down(xs) {
			max-width: 100%;
			transform: scale(1.18);
			margin-bottom: 20px;
		}
	}

	&__title {
		font-size: 17px;
		font-weight: 700;
		margin-bottom: 5px;
		margin-top: 10px;
		color: $main-font-color;
		line-height: 22px;
		&:hover {
			color: $main-font-color;
			text-decoration: none;
		}
	}
	&__date{
		font-size: 15px;
		font-weight: normal;
		margin-bottom: 30px;
		color: $main-font-color;
		&:hover {
			color: $main-font-color;
			text-decoration: none;
		}
	}
	&__more-btn {
		color: #fff;
		font-weight: 700;
		font-size: 15px;
		line-height: 15px;
		padding-left: 15px;
		width: 100%;
		max-width: 285px;
		padding: 8px 15px;
		border: 1px solid $accent-color;
		background-color: $accent-color;
		text-transform: uppercase;
		text-decoration: none;
		position: absolute;
		bottom: 0;
		text-align: left;
		@include media-breakpoint-down(lg) {
				max-width: 290px;
			}
		@include media-breakpoint-down(md) {
			max-width: 329px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 509px;
		}
		@include media-breakpoint-down(xs) {
			max-width: calc(100% - 40px);
		}
		&:hover {
			text-decoration: none;
			color: $main-font-color;
			background-color: #fff;
			.news-item__button-text {
				.nav-arrow {
					border-right: 2px solid #000;
					border-bottom: 2px solid #000;
				}
			}
		}
		.nav-arrow {
			position: absolute;
			top: 4px;
			right: -32px;
			display: inline-block;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
			width: 9px;
			height: 9px;
			transform: rotate(-46deg);
			margin-right: 10px;
			&--1 {
				right: -23px;
			}
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}
	&__button-text {
		font-family: $font-family-normal;
		position: relative;
	}
}