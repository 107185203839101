.model-links {
	margin-top: 30px;
	margin-bottom: 30px;
	@include media-breakpoint-down(lg) {
		margin-top: 10px;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 30px;
	}

	&__list {
		padding-left: 0;
	}

	&__item {
		list-style: none;
		margin-bottom: 20px;

		&:first-child {
			max-width: 270px;
			@include media-breakpoint-down(lg) {
				max-width: 215px;
			}
			@include media-breakpoint-down(md) {
				max-width: none;
			}
		}
		&:nth-child(2) {
			min-width: 358px;
			@include media-breakpoint-down(lg) {
				min-width: 265px;
			}
			@include media-breakpoint-down(md) {
				max-width: none;
			}
		}
		&:nth-child(3) {
			@include media-breakpoint-down(lg) {
				min-width: 231px;
			}
		}

		@include media-breakpoint-down(lg) {
			padding-left: 10px;
			padding-right: 10px;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 45px;
		}
	}

	&__item-link {
		display: block;
		width: 100%;
		min-height: 100%;
		padding-top: 4px;
		padding-left: 10px;
		padding-bottom: 5px;
		background-color: #fff;
		transition: background-color 0.1s linear, border 0.2s linear;
		border-left: 5px solid $accent-color;
		&:hover {
			text-decoration: none;
			background-color: #ECECEC;
			border-left: 10px solid $accent-color;
		}
		@include media-breakpoint-down(lg) {
			padding-left: 6px;
		}
		@include media-breakpoint-down(md) {
			border-left: 9px solid $accent-color;
			padding-left: 15px;
			&:hover {
				border-left: 16px solid $accent-color;
			}
		}
	}

	&__title {
		position: relative;
		font-size: 25px;
		line-height: 28px;
		text-transform: uppercase;
		font-family: $font-family;
		margin-bottom: 15px;
		width: fit-content;
		display: inline-block;
		@include media-breakpoint-down(lg) {
			margin-bottom: 6px;
			font-size: 18px;
			line-height: 20px;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 15px;
			font-size: 25px;
			line-height: 28px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 23px;
		}

		.nav-arrow {
			position: absolute;
			top: 9px;
			right: -32px;
			display: inline-block;
			border-right: 2px solid $accent-color;
			border-bottom: 2px solid $accent-color;
			width: 10px;
			height: 10px;
			transform: rotate(-46deg);
			margin-right: 10px;
			&--1 {
				right: -23px;
			}
			@include media-breakpoint-down(md) {
				top: 6px;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}

	&__text {
		font-size: 17px;
		line-height: 18px;
		font-family: $font-family-normal;
		margin-top: 5px;
		margin-bottom: 5px;
		@include media-breakpoint-down(lg) {
			font-size: 12px;
			line-height: 14px;
		}
		@include media-breakpoint-down(md) {
			font-size: 17px;
			line-height: 18px;

		}
		@include media-breakpoint-down(sm) {
			font-size: 15px;
			line-height: 16px;
		}
	}
}

.car-in-stock-links {
	@include media-breakpoint-down(lg) {
		position: relative;
		top: -70px;
	}
	@include media-breakpoint-down(md) {
		top: -260px;
	}
	@include media-breakpoint-down(sm) {
		top: 0px;
		overflow-x: hidden;
	}
	.model-links {
		&__wrapper {
			@include media-breakpoint-down(lg) {
				max-width: 500px;
				width: 100%;
			}
			@include media-breakpoint-down(md) {
				position: absolute;
				max-width: 380px;
			}
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				position: relative;
			}
		}

		&__item {
			list-style: none;
			&:first-child {
				min-width: 40%;
				max-width: 100%;
				width: 100%;
				@include media-breakpoint-down(md) {
					margin-bottom: 15px;
					max-width: 100%;
					width: 100%;
				}
				@include media-breakpoint-down(sm) {
					min-width: unset;
					margin-bottom: 30px;
				}
			}
			&:nth-child(2) {
				max-width: 30%;
				@include media-breakpoint-down(md) {
					min-width: unset;
					max-width: 50%;
				}
				@include media-breakpoint-down(sm) {
					max-width: 100%;
				}
			}
			&:nth-child(3) {
				max-width: 30%;
				@include media-breakpoint-down(md) {
					max-width: 50%;
					min-width: unset;
				}
				@include media-breakpoint-down(sm) {
					max-width: 100%;
				}
			}
		}

		&__item-link {
			padding-top: 10px;
			padding-bottom: 10px;
			@include media-breakpoint-down(md) {
				padding-left: 10px;
			}
		}

		&__title {
			font-size: 25px;
			line-height: 28px;
			letter-spacing: 0;
			@include media-breakpoint-down(md) {
				font-size: 20px;
				margin-bottom: 0px;
				line-height: 22px;
			}
		}

		&__text {
			font-size: 17px;
			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}
	}
}