.actions {
	font-family: $font-family;
	.container {
		position: relative;
	}
	&__title {
		font-size: 28px;
		color: $main-font-color;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 50px;
		padding-left: 0;
		margin-bottom: 30px;
		@include media-breakpoint-down(sm) {
			text-align: left;
			padding-left: 0;
			font-size: 25px;
		}
		@include media-breakpoint-down(xs) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 4px;
		}
	}
	&__list {
		padding-left: 0;
		padding-left: 0px;
		@include media-breakpoint-up(xl) {
			padding-left: 4px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 10px;
		}
	}
	&__see-all-link {
		font-size: 20px;
		font-weight: 700;
		font-style: italic;
		color: $main-font-color;
		text-decoration: underline;
		text-transform: uppercase;
		transition: color 0.1s linear;
		position: absolute;
		right: 28px;
		bottom: -20px;
		&:hover {
			color: $accent-color;
		}
	}
}

.actions-item {
	list-style: none;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 30px;
	position: relative;
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 20px;
	}
	@include media-breakpoint-down(md) {
		padding-left: 10px;
		padding-right: 10px;
	}

	&:hover {
		.actions-item__button-text {
			.nav-arrow {
				border-right: 2px solid $accent-color;
				border-bottom: 2px solid $accent-color;
			}
		}
	}

	&__wrapper {
		margin: 0 auto;
		position: relative;
		height: 100%;
		position: relative;
		display: block;
		text-decoration: none;
		&:hover {
			 .actions-item__more-btn {
				color: $accent-color;
			}
		}
	}

	&__img {
		width: 100%;
		max-width: 380px;
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
		@include media-breakpoint-down(xs) {
			max-width: 100%;
			margin-bottom: 20px;
		}
	}
	&__more-btn {
		color: #fff;
		font-weight: 400;
		font-size: 15px;
		line-height: 15px;
		padding-left: 15px;
		width: 100%;
		max-width: 380px;
		padding: 8px 15px;
		border: 1px solid #000;
		background-color: #000;
		text-transform: uppercase;
		text-decoration: none;
		opacity: 0.81;
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: left;
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
		&:hover {
			text-decoration: none;
		}
		.nav-arrow {
			position: absolute;
			top: 6px;
			right: -32px;
			display: inline-block;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
			width: 9px;
			height: 9px;
			transform: rotate(-46deg);
			margin-right: 10px;
			&--1 {
				right: -23px;
			}
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}
	&__button-text {
		font-family: $font-family;
		position: relative;
	}
}

.model-actions {
	.actions-item {
		@include media-breakpoint-down(md) {
			&:last-child {
				display: block !important;
			}
		}
	}
}