.block-car-in-stock-price {
	background-color: #F8F8F8;
	background-image: url('/files/resources/images/max-price-arrows.png');
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	padding-top: 37px;
	padding-bottom: 35px;
	&__min-price {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: baseline;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	&__min-price-text {
		font-size: 25px;
		line-height: 25px;
		font-family: $font-family-normal;
		margin-bottom: 0;
		margin-top: 0px;
		@include media-breakpoint-down(sm) {
			margin-top: 5px;
		}
	}

	&__min-price-value {
		font-size: 29px;
		line-height: 30px;
		font-family: $font-family;
		font-style: italic;
		font-weight: 700;
		@include media-breakpoint-down(sm) {
			margin-top: 10px;
		}
	}

	&__text {
		font-size: 18px;
		line-height: 25px;
		font-family: $font-family-normal;
		margin-top: 25px;
	}

	&__discount {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&__discount-item {
		font-size: 25px;
		font-family: $font-family;
		margin-right: 50px;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}
	}

	&__discount-value {
		color: #FE5434;
		font-style: italic;
		font-weight: bold;
	}

	&__phone {
		font-size: 14px;
		font-family: $font-family-normal;
		margin-bottom: 0;
	}
}
