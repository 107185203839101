.corvette-container {

  .standard-gallery-corvette-top {
    max-height: 500px;
    overflow: hidden;
    animation: appear 1s;
    @include media-breakpoint-down(xl) {
      .nav-list {
        margin-top: -58px;
      }
      .slick-slider {
        height: auto !important;
        margin-bottom: -47px;
        .slick-list {
          height: auto !important;
          .slick-slide {
            height: auto !important;
            .slider-item-standard {
              height: auto !important;
              .content-standard {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .slick-dots {
      top: -77px !important;
      @include media-breakpoint-down(md) {
        top: -57px !important;
      }
      }
  }

  @keyframes appear  {
    0% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }
    50% {
      opacity: 0;
      height: 500px;
      overflow: hidden;
    }

    99% {
      opacity: 0.7;
      height: 500px;
      overflow: hidden;
    }

    100% {
      opacity: 1;
      overflow: unset;
      height: unset;
    }
  }



  .model-specs {
    &__list {
      @include media-breakpoint-down(lg) {
        justify-content: space-between !important;
      }
    }
  }

  strong {
    font-weight: 700 !important;
    font-size: 20px;
  }

  .features_1_2 ,.features_1_3, .features_1_4  {
    padding-right: 15px;

    p {
      padding-right: 15px;
    }
  }

  .features_1_3 {
    @include media-breakpoint-down(md) {
      h3 {
        margin-top: 40px;
      }
    }
  }

  #video-interior {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .electron-diff {
    .features_1_3 {
      max-width: 100%;
      flex-basis: 100%;
    }
    .features_2_3 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}