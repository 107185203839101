.model-text {
	h2 {
		@include media-breakpoint-down(lg) {
			font-size: 30px;
		}
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
	}

	p {
		@include media-breakpoint-down(xs) {
			font-size: 16px;
		}
	}
}