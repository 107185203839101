.text-standard-block {
	padding-top: 20px;
	article {
		margin-bottom: 50px;
	}

	h1 {
		font-family: $font-family;
		font-size: 40px !important;
		font-style: italic;
		font-weight: 700;
		color: $main-font-color;
		letter-spacing: 0.5px;
		margin-top: 0px !important;
		margin-bottom: 30px!important;
		line-height: 50px;
		text-transform: uppercase;
	}
	h2 {
		font-family: $font-family;
		font-size: 35px;
		font-style: italic;
		font-weight: 700;
		color: $main-font-color;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
		line-height: 46px;
	}
	h3 {
		font-family: $font-family;
		font-size: 30px;
		font-style: italic;
		font-weight: 700;
		color: $main-font-color;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
		line-height: 37px;
	}
	h4 {
		font-family: $font-family;
		font-size: 20px;
		font-style: italic;
		font-weight: 700;
		color: $main-font-color;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
		line-height: 27px;
	}
	p {
		font-family: $font-family-normal;
		font-size: 18px;
		font-weight: 400;
		color: $main-font-color;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
	}
	a {
		color: $accent-color;
		text-decoration: underline;
	}
}