.camaro-wrapper {
	background-color: #000;
	color: #fff;
	padding-bottom: 100px;

	.standard-gallery-camaro-top {
		max-height: 500px;
		overflow: hidden;
		animation: appear 1s;
		@include media-breakpoint-up(xl) {
			.slider-standard {
				height: 563px !important;
				.content-standard {
					img {
						width: 100%;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.nav-list {
				margin-top: -58px;
			}
			.slick-slider {
				height: auto !important;
				margin-bottom: -47px;
				.slick-list {
					height: auto !important;
					.slick-slide {
						height: auto !important;
						.slider-item-standard {
							height: auto !important;
							.content-standard {
								img {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.slider-standard {
				height:auto !important;
			}
		}

		.slick-dots {
			display: none;
		}
	}

	@keyframes appear  {
		0% {
			opacity: 0;
			height: 500px;
			overflow: hidden;
		}
		50% {
			opacity: 0;
			height: 500px;
			overflow: hidden;
		}

		99% {
			opacity: 0.7;
			height: 500px;
			overflow: hidden;
		}

		100% {
			opacity: 1;
			overflow: unset;
			height: unset;
		}
	}

	#traverse-gallery-top {
		margin-top: 0;
	}

	.tth-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.inner {
			width: auto !important;
		}
	}

	.fotorama {
		.fotorama__wrap {
			.fotorama__nav {
				display: block;
				height: 196px !important;
				width: 100%;
				@include media-breakpoint-down(sm) {
					height: 70px !important;
				}
			}
		}
	}

	.fotorama__active .fotorama__thumb {
		border: none !important;
	}

	.fotorama__nav__frame--thumb {
		width: 316px !important;
		height: 177px !important;

		@include media-breakpoint-down(sm) {
			width: 78px !important;
			height: 44px !important;
		}
	}

	.slider-text-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		p {
			font-size: 18px !important;
		}

		.fck_authorsinput {
			padding-right: 30px;
		}
	}

	.iframe_content {
		iframe {
			width: 100%;
			@include media-breakpoint-down(md) {
				max-width: 85%;
			}
		}

		.override-text-in-colorizer {
			padding-top: 20px;
			padding-bottom: 30px;
			position: relative;
			top: 240px;
			background-color: #000;
		}

		h2 {
			font-family: "Louis", sans-serif;
			font-size: 35px;
			font-style: italic;
			font-weight: 700;
			color: #F4BC00;
			letter-spacing: 0.5px;
			margin-bottom: 30px;
			text-transform: uppercase;
			background-color: #000;
		}

		p {
			font-size: 18px !important;
			color: #fff;
			font-style: normal;
			text-transform: none;
			line-height: 26px;
			max-width: 50%;
			@include media-breakpoint-down(md) {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.text-img {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: 0;
		overflow-x: hidden;
		margin: 0 !important;
		max-width: 100%;

		img {
			width: 100%;
		}

		&--control {
			@include media-breakpoint-down(lg) {
				img {
					height: auto;
					max-height: 465px;
				}

			}
			@include media-breakpoint-down(sm) {
				img {
					height: auto;
					max-height: 300px;
				}

			}
			@include media-breakpoint-down(xs) {
				img {
					height: auto;
					max-height: 200px;
				}

			}
		}
	}

	.watch-interior {
		height: 615px;
		margin-top: 30px;
		margin-bottom: 80px;
		@include media-breakpoint-down(lg) {
			height: 465px;
		}
		@include media-breakpoint-down(md) {
			height: 345px;
		}
		@include media-breakpoint-down(sm) {
			height: 255px;
		}
		@include media-breakpoint-down(sm) {
			height: auto;
		}
	}

	.rich-text-component {
		margin-bottom: 100px;
		p {
			color: #fff;
			font-size: 18px;
		}
	}

	#camaro-colorizer {
		margin-top: -150px;
	}

	.regime-choice-wrapper {
		.container {
			position: relative;
		}


		#regime-choice {
			position: absolute;
			top: 105px;
			left: -50px;
			padding-left: 50px;
			max-width: 44%;
			background-color: #000;
			padding-left: 40px;
		}
	}

	.list-unorder {
		color: #fff;
	}

	.container {
		overflow-x: hidden;
	}

	#safety-img {
		max-width: 100%;
	}

	.cnt_par_1 {
		max-width: 100%;
		width: 100%;
	}

	.camaro-links-buttons {
		display: flex;
		justify-content: center;
		flex-direction: row;
		margin-top: 30px;
		.item {
			margin-top: 20px;
			display: block;
			.img {

			}
		}
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 30px;
		}
	}

	.tth-image {
		max-width: 100%;
		width: 100%;
		margin-top: 40px;
	}

	.more-btn-camaro {
		@include media-breakpoint-down(xs) {
			max-width: 270px !important;
		}

		.button-text {
			left: 0 !important;
		}
	}
}

.camaro-gallery-wrapper {
	.fotorama {
		@include media-breakpoint-down(md) {
			height: auto !important;
		}
	}

	.fotorama__thumb {
		img {
			height: 100% !important;
			//width: initial !important;
		}
	}
}
