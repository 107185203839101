.model-tabs {
	margin-top: 75px;
	margin-bottom: 75px;
	height: 77px;

	.tabs-block {
		display: block;
		min-height: 100%;
	}

	.tabs {
		display: block;
		min-height: 100%;
		background-color: #434242;
		height: 77px;
		.tabs-list {
			padding-left: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 0;
			height: 77px;
			@include media-breakpoint-down(md) {
				justify-content: space-between;
			}
			@include media-breakpoint-down(xs) {
				overflow-x: auto;
				white-space: nowrap;
			}
		}

		.tabs-item {
			font-size: 25px;
			text-transform: uppercase;
			font-family: $font-family;
			font-weight: 700;
			font-style: italic;
			list-style: none;
			@include media-breakpoint-down(md) {
				font-size: 19px;
				margin-right: 0;
				margin-left: 0;
			}
			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
			@include media-breakpoint-down(xs) {
				margin-right: 20px;
			}
			&:hover {
				color: #000;

				a {
					color: $accent-color;
					text-decoration: none;
				}
			}

			a {
				transition: color 0.1s linear;
				color: #fff;
			}
		}
	}
	.tab_container {
		margin-top: 30px;
	}
}