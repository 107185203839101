@font-face {
	font-family: 'Louis';
	src: url('/files/resources/fonts/LouisRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Louis';
	src: url('/files/resources/fonts/LouisItalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
