.test-drive-container {
	padding: 0 0 !important;
	max-width: 843px;
	@include media-breakpoint-down(sm) {
		max-width: 500px !important;
		.fw_win_content_block {
			width: auto !important;
		}
	}

	@include media-breakpoint-down(xs) {
		max-width: 320px !important;
		.fw_win_content {
			padding-left: 15px;
			padding-right: 15px;
		}

		.fw_win_title {
			padding-left: 15px;
			padding-right: 40px;
			line-height: 30px;
		}
	}

	.ik_select_dropdown {
		left: 0px !important;
		top: 34px !important;
		width: 100% !important;
	}

	.field_wrapper {
		flex: 50%;
		margin-bottom: 10px;
		&:nth-child(2) {
			.formjor-asterisk {
				display: none;
			}
		}
		&:nth-child(11) {
			flex: 100%;
		}
		&:nth-child(12) {
			flex: 100%;
		}
		@include media-breakpoint-down(sm) {
			flex: 100%;
		}
	}

	.form_inner_wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 840px;
	}

	.name-wrapper {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}

	.date-input {
		position: relative;
		input {
			height: 34px;
		}

		.ui-datepicker {
			top: 34px !important;
			left: 0 !important;
			width: 100%;
			border-radius: 0px;
			background: #FCF9F9;

			.ui-datepicker-header {
				border: none;
				background: none;
				font-size: 15px;
				color: #000;
				font-weight: 400;
				font-family: $font-family-normal;

				.ui-datepicker-year {
					display: none;
				}

				.ui-state-hover {
					border: 1px solid transparent;
				}

				.ui-state-disabled {
					border: 1px solid transparent;
				}

				.ui-datepicker-prev {
					border: 1px solid transparent;
					&-hover {
						border: 1px solid transparent;
						background: none;
						left: 2px !important;
						top: 2px !important;
					}
					&:after {
						content: '';
						position: absolute;
						top: 11px;
						right: 0px;
						border-right: 2px solid #000;
						border-bottom: 2px solid #000;
						width: 9px;
						height: 9px;
						margin-right: 10px;
						transform: rotate(-228deg);
					}
					.ui-icon {
						background-image: none;
					}
				}

				.ui-datepicker-next {
					&-hover {
						border: 1px solid transparent;
						background: none;
					}
					&:after {
						content: '';
						position: absolute;
						top: 11px;
						right: 0px;
						border-right: 2px solid #000;
						border-bottom: 2px solid #000;
						width: 9px;
						height: 9px;
						margin-right: 10px;
						transform: rotate(-44deg);
					}
					.ui-icon {
						background-image: none;
					}
				}

			}

			.ui-datepicker-calendar {
				thead {
					tr {
						th {
							font-size: 13px;
							color: #707070;
							font-weight: 400;
						}
					}
				}

				tbody {
					tr {
						border: 1px solid transparent;
						height: 20px;
						&:hover {
							border: none;
							background: none;
							box-shadow: none;
						}
					}

					td {
						span {
							border:none;
						}
					}

					.ui-state-default {
						color: #707070;
						font-size: 12px;
						text-align: center;
					}

					.ui-datepicker-today {
						background-color: transparent;
						border:none;
					}

					.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
						border: 1px solid transparent;
						background: none;
						color: #707070;
						font-weight: 400;
						font-family: $font-family-normal;
					}

					.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
						border: 1px solid transparent;
						background: none;
						color: #707070;
						font-weight: 400;
						font-family: $font-family-normal;
					}

					.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
						border: 1px solid transparent;
						background: #1082D2;
						color: #fff;
						font-weight: 400;
						font-family: $font-family-normal;
					}
				}
			}
		}

		.ftype_time {
			position: relative;
			&:after {
				position: absolute;
				right: 3px;
				top: 8px;
				content: '';
				width: 20px;
				height: 20px;
				background: url("/files/resources/images/icons/clock-circular-outline.svg") no-repeat;
			}
		}
		.ftype_date {
			position: relative;
			&:after {
				position: absolute;
				right: 3px;
				top: 8px;
				content: '';
				width: 20px;
				height: 20px;
				background: url("/files/resources/images/icons/calendar.svg") no-repeat;
			}
		}

		.container_timepicker {
			top: 33px !important;
			left: 0 !important;
			width: 100%;
			border-radius: 0px;
			background: #FCF9F9;
			padding-top: 50px;

			.table_timepicker {
				width: 100%;
			}

			.content_timepicker {
				td {
					position: relative;
				}
				td:first-child {
					left: 7px;
				}
				td:nth-child(2) {
					right: 7px;
				}
				td:nth-child(3) {
					left: 7px;
				}
				td:nth-child(4) {
					right: 7px;
				}
				td:nth-child(5) {
					left: 7px;
				}
				td:nth-child(6) {
					right: 7px;
				}
			}

			.cell_delimiter {
				display: none;
			}

			.header_timepicker {
				.cell_header_timepicker {
					position: relative;
					&:first-child:after {
						content: '';
						position: absolute;
						top: -37px;
						right: 20px;
						width: 60px;
						height: 30px;
						background-image: url('/files/resources/images/time/sunrise.png');
						background-repeat: no-repeat;
						background-position: center center;
						@include media-breakpoint-down(xs) {
							right: 13px;
						}
					}

					&:nth-child(2):after {
						content: '';
						position: absolute;
						top: -44px;
						right: 28px;
						width: 60px;
						height: 44px;
						background-image: url('/files/resources/images/time/sun.png');
						background-repeat: no-repeat;
						background-position: center center;
						@include media-breakpoint-down(sm) {
							right: 30px;
						}
						@include media-breakpoint-down(xs) {
							right: 20px;
						}
					}

					&:nth-child(3):after {
						content: '';
						position: absolute;
						top: -50px;
						right: 22px;
						width: 60px;
						height: 42px;
						background-image: url('/files/resources/images/time/down.png');
						background-repeat: no-repeat;
						background-position: center center;
						@include media-breakpoint-down(xs) {
							right: 19px;
						}
					}

					a {
						background: #fcf9f9;
						border: none;
						color: $main-font-color !important;
						font-weight: 400;
						font-size: 13px;
						text-transform: none;
					}
				}
			}

			.selected_timepicker {
				a {
					display: inline;
					padding: 4px;
					color: #fff !important;
					background-color: $accent-color;
				}
			}

			tr {
				border: 1px solid transparent;
				&:hover {
					border: none;
					background: none;
					box-shadow: none;
				}
				td {
					a {
						font-size: 12px;
						width: 100%;
						background: none;
						color: #707070 !important;
						border: none;
						font-weight: 400;
					}
				}
			}
		}
	}


	.date-label {
		margin-bottom: -2px;
		label {
			margin-bottom: 6px;
		}
	}

	.name-wrapper {
		input {
			height: 34px;
		}
	}

	.name {
		margin-bottom: -2px;
		label {
			margin-bottom: 6px;
		}

		input {
			height: 34px;
		}
	}

	.ftype_textarea {
		margin-bottom: 20px;
	}

	.cell_delimiter {
		display: none;
	}

	.input_wrapper {
		input {
			font-family: $font-family-normal !important;
		}
	}

	.label_wrapper {
		margin-bottom: 2px;
		.i_agree_personal_data-label {
			@include media-breakpoint-down(sm) {
				padding-left: 15px !important;
			}
		}
		label {
			top: 0 !important;
		}
	}

	.ftype_checkbox .form-control {
		top: -23px;
		@include media-breakpoint-down(sm) {
			top: -44px;
		}
	}

	.input-form-wrapper {
		padding-left: 0 !important;
		padding-right: 0 !important;

		&:not(:nth-child(7)) {
			margin-bottom: 0px;
		}
	}

	.disclaimer {
		padding-left: 15px;
		padding-right: 15px;
	}

	.form_submit_button {
		margin-right: 15px;
		margin-left: 15px;
	}

	.form_success_answer_wrapper {
		p {
			font-size: 16px;
		}

		h2 {
			font-size: 25px !important;
		}

		.middle_margin_bottom {
			margin-top: 20px;
		}

		.list-with-link {
			a {
				color: $main-font-color;
				transition: color 0.1s linear;
				&:hover {
					text-decoration: none;
					color: $accent-color;
				}
			}
		}

		.img-responsive {
			max-width: 100%;
			width: 100%;
		}

		.answer_result_block {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			@include media-breakpoint-down(xs) {
				flex-direction: column;
			}
		}

		.close_window_button {
			font-family: $font-family-normal;
			cursor: pointer;
			display: block;
			text-align: center;
			position: relative;
			color: #fff;
			font-weight: 700;
			font-size: 15px;
			line-height: 15px;
			padding-top: 8px;
			padding-bottom: 8px;
			margin-top: 30px;
			width: 100%;
			max-width: 100%;
			border: 1px solid $accent-color;
			background-color: $accent-color;
			text-transform: uppercase;
			text-decoration: none;
			transition: color 0.1s linear, background-color 0.1s linear;
			&:hover {
				text-decoration: none;
				color: $main-font-color;
				background-color: #fff;
			}
		}
	}
}