.pagination {
	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.item {
		padding-left: 5px;
		padding-right: 5px;
		min-width: 30px;
		height: 30px;
		background-color: #F9F6F6;
		font-size: 21px;
		color: $main-font-color;
		transition: color 0.1s linear, background-color 0.1s linear;
		text-decoration: none;
		text-align: center;
		&:not(:last-child) {
			margin-right: 5px;
		}
		&:hover {
			color: #fff;
			background-color: $accent-color;
			text-decoration: none;
		}
	}

	.to_left, .to_right {
		font-size: 33px;
		line-height: 29px;
		background-color: transparent;
	}

	.act {
		background-color: $accent-color;
		color: #fff;
	}
}

.pagination-wrapper {
	margin-top: 25px;
}